import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = 'https://tpomobi.shop'
// const baseUrl = 'http://localhost:1111'

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    findAllWords: builder.query({
      query: () => ({
        url: '/getProductsAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['Products'],
    }),
    getSpoProductsFromContacts: builder.query({
      query: () => ({
        url: '/getSpoProductsFromContacts',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['PersonalSalesList'],
    }),
    getProductsLimit: builder.query({
      query: (query) => ({
        url: '/getProductsAdminLimit',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          limit: query.limit || 50,
          search: query.search || '',
          sku: query.sku || '',
        },
      }),
      providesTags: ['Products'],
    }),
    getProductsBySkus: builder.query({
      query: (query) => ({
        url: '/getProductsBySku',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          skus: query.skus || '',
        },
      }),
      providesTags: ['Products'],
    }),
    getProductById: builder.query({
      query: (id) => ({
        url: `/getProductsAdmin/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['Product'],
    }),
    getAllOrders: builder.query({
      query: (tradeName = localStorage.getItem('name') || 'TPO') => ({
        url: `/getOrdersAdmin?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['ShipmentOrders'],
    }),
    getFastOrderItems: builder.query({
      query: (query) => ({
        url: '/getOrdersUniqueAdminByTradeName',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: query.tradeName || '',
        },
      }),
      transformResponse: (
        response, //sort by name asc
      ) => response.sort((a, b) => (a.name > b.name ? 1 : -1)),
    }),
    getAllOrdersEmitido: builder.query({
      query: (query) => ({
        url: `/getOrdersAdminEmitido`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: localStorage.getItem('name') || 'TPO',
          limit: query.limit || 50,
          search: query.search || '',
          status: query.status ? query.status.map((obj) => obj._id).join(',') : '',
          platform: query.platform || '',
          tradeNames: query.tradeNames ? query.tradeNames.join(',') : '',
          deliveryDate: query.deliveryDate || '',
          month: query.month || '',
        },
      }),
      providesTags: ['ShipmentOrders'],
    }),
    getStatsOrders: builder.query({
      query: (tradeName = localStorage.getItem('name') || 'TPO') => ({
        url: `/getStatsOrdersAdmin?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['ShipmentOrders'],
    }),
    getOrdersEmetidoDashboard: builder.query({
      query: (query) => ({
        url: `/getOrdersAdminEmitido123`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: localStorage.getItem('name') || 'TPO',
        },
      }),
      providesTags: ['ShipmentOrders'],
    }),
    getAllOrdersWithLimit: builder.query({
      query: (query) => ({
        url: `/getOrdersAdminLimit`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: localStorage.getItem('name') || 'TPO',
          limit: query.limit || 50,
          search: query.search || '',
          status: query.status ? query.status.map((obj) => obj._id).join(',') : '',
          platform: query.platform || '',
          tradeNames: query.tradeNames ? query.tradeNames.join(',') : '',
          deliveryDate: query.deliveryDate || '',
          month: query.month || '',
          simpleDeliveryDate: query.simpleDeliveryDate || '',
        },
      }),
      providesTags: ['ShipmentOrders'],
    }),
    getOrdersShipment: builder.query({
      query: (query) => ({
        url: `/getOrdersAdminLimitShipment`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: localStorage.getItem('name') || 'TPO',
          limit: query.limit || 50,
          search: query.search || '',
          status: query.status ? query.status.map((obj) => obj._id).join(',') : '',
          platform: query.platform || '',
          tradeNames: query.tradeNames ? query.tradeNames.join(',') : '',
          deliveryDate: query.deliveryDate || '',
          month: query.month || '',
        },
      }),
      providesTags: ['ShipmentOrders'],
    }),
    getTradeNamesFromContacts: builder.query({
      query: () => ({
        url: '/getTradeNamesFromContacts',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: localStorage.getItem('name') || 'TPO',
        },
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['TradeNames'],
    }),
    getOrdersByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/getOrdersAdminByTradeName?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      transformResponse: (response) => response.reverse(),
    }),
    getAdressByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/getAddressByTradename?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },

        method: 'GET',
      }),
    }),
    getAdressesByTradeNames: builder.query({
      query: (tradeNames) => ({
        url: `/getAdressesByTradeNames?tradeNames=${encodeURIComponent(tradeNames)}`,
        headers: {
          'Content-Type': 'application/json',
        },

        method: 'GET',
      }),
    }),
    getOrdersByTradeNameFilters: builder.query({
      query: (query) => ({
        url: `/getOrdersAdminByTradeNameFilters`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: query.tradeName || '',
          limit: query.limit || 50,
          search: query.search || '',
          status: query.status ? query.status.map((obj) => obj._id).join(',') : '',
          platform: query.platform || '',
          tradeNames: query.tradeNames ? query.tradeNames.join(',') : '',
          deliveryDate: query.deliveryDate || '',
          month: query.month || '',
        },
      }),

      // transformResponse: (response) => response.reverse(),
    }),

    getOrdersById: builder.query({
      query: (id) => ({
        url: `/getOrdersAdmin/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['Order'],
    }),
    checkActualItems: builder.mutation({
      query: (body) => ({
        url: `/checkActualProducts`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    getOrdersByIds: builder.query({
      query: (ids) => ({
        url: `/getOrdersByIds`,
        headers: {
          'Content-Type': 'application/json',
        },
        params: { ids }, // Use the array directly as the value for the 'ids' parameter
        method: 'GET',
      }),
      providesTags: ['ShipmentOrders'],
    }),
    getAllContacts: builder.query({
      query: (tradeName = localStorage.getItem('name') || 'TPO') => ({
        url: `/getContactsAdmin?tradeName=${tradeName}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['Contacts'],
    }),
    getAllCotactsNew: builder.query({
      query: (query) => ({
        url: `/getContactsAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: localStorage.getItem('name') || 'TPO',
          adminType: query.adminType || '',
        },
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['Contacts'],
    }),
    getContactsById: builder.query({
      query: (id) => ({
        url: `/getContactsAdmin/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['Contact'],
    }),
    getContactByPhone: builder.query({
      query: (query) => ({
        url: `/getContactByPhoneAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          phone: query.phone,
        },
      }),
      providesTags: ['Contact'],
    }),
    getClientsAndOrders: builder.query({
      query: (query) => ({
        url: '/getClientsAndOrders',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          limit: query.limit || 20,
          search: query.search || '',
          adminType: query.adminType || '',
        },
      }),
      transformResponse: (response) => response.reverse(),
    }),
    getClientsAndOrdersAll: builder.query({
      query: () => ({
        url: '/getClientsAndOrdersAll',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      transformResponse: (response) => response.reverse(),
    }),
    createNewOrder: builder.mutation({
      query: (body) => ({
        url: '/createOrderAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/deleteOrderAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: {
          _id: id,
        },
      }),
    }),
    changeItemsInOrder: builder.mutation({
      query: (body) => ({
        url: `/updateOrderItemsAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    updateStatusinOrder: builder.mutation({
      query: (body) => ({
        url: `/updateStatusOrderAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    updateStatusinManyOrders: builder.mutation({
      query: (body) => ({
        url: `/updateStatusOrderAdminMany`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    payOrders: builder.mutation({
      query: (body) => ({
        url: `/updatePaidValueOrderAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    createContact: builder.mutation({
      query: (body) => ({
        url: '/createContactAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    deleteContact: builder.mutation({
      query: (id) => ({
        url: `/deleteContactAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          _id: String(id),
        }),
      }),
    }),
    updateContact: builder.mutation({
      query: (body) => ({
        url: `/updateContactAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    updateProduct: builder.mutation({
      query: (body) => ({
        url: `/updateProductAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    importContacts: builder.mutation({
      query: (body) => ({
        //form data
        url: `/importContactsAdmin`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        body,
      }),
      providesTags: ['Contacts'],
    }),
    // importProducts: builder.mutation({
    //   query: (body) => ({
    //     //form data
    //     url: `/importProductsAdmin`,
    //     headers: {
    //       'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    //     },

    //     method: 'POST',
    //     body
    //   }),
    //   providesTags: ['Products'],
    // }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/deleteProductAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          _id: String(id),
        }),
      }),
    }),
    createProduct: builder.mutation({
      query: (body) => ({
        url: '/createProductAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeClient: builder.mutation({
      query: (body) => ({
        url: `/changeClientOrder`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    getAllStatuses: builder.query({
      query: (query) => ({
        url: `/getStatusAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          siteTradename: localStorage.getItem('name') || 'TPO',
        },
      }),
      providesTags: ['Statuses'],
    }),
    createStatus: builder.mutation({
      query: (body) => ({
        url: '/createStatusAdmin',
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeProductStatus: builder.mutation({
      query: (body) => ({
        url: `/updateStatusProductsAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeStatusAdmin: builder.mutation({
      query: (body) => ({
        url: `/updateNameOfStatusAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeBuisness: builder.mutation({
      query: (body) => ({
        url: `/updateTradeNameContactAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeDeliveryDate: builder.mutation({
      query: (body) => ({
        url: `/changeDeliveryDate`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    sendNotificationOrderUpdateWhatsapp: builder.mutation({
      query: (body) => ({
        url: `/sendNotificationOrderUpdateWhatsapp`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    sendDocsOrderUpdateWhatsapp: builder.mutation({
      query: (body) => ({
        url: `/sendDocsOrderWhatsapp`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    sendBotStartWhatsapp: builder.mutation({
      query: (body) => ({
        url: `/sendBotStartWhatsapp`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeOrderObj: builder.mutation({
      query: (body) => ({
        url: `/updateOrderObjAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeObjStock: builder.mutation({
      query: (body) => ({
        url: `/updateStockAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeObjProveedor: builder.mutation({
      query: (body) => ({
        url: `/updateProveedorAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeObjEntryPrice: builder.mutation({
      query: (body) => ({
        url: `/updateEntryPriceAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeObjInform: builder.mutation({
      query: (body) => ({
        url: `/updateInformAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    changeOrderPaymentType: builder.mutation({
      query: (body) => ({
        url: `/updateOrderPaymentTypeAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeOrderDeliveryTime: builder.mutation({
      query: (body) => ({
        url: `/updateOrderDeliveryTimeAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    getHistory: builder.query({
      query: (query) => ({
        url: `/getFacturaHistoryAdmin?tradeName=${localStorage.getItem('name') || 'TPO'}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          startDate: query.startDate || '',
          endDate: query.endDate || '',
        },
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['History'],
    }),
    getLastNumInHistory: builder.mutation({
      query: (cuenta) => ({
        url: `/getLastNumFacturaHistoryAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: {
          cuenta: cuenta,
        },
      }),
      providesTags: ['History'],
    }),
    getUniqueCuentas: builder.query({
      query: () => ({
        url: `/getUniqueCuentasAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['History'],
    }),
    createNewHistoryValue: builder.mutation({
      query: (body) => ({
        url: `/createFacturaHistoryAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    deleteFromHistory: builder.mutation({
      query: (body) => ({
        url: `/deleteFacturaHistoryAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    updateStatusHistory: builder.mutation({
      query: (body) => ({
        url: `/updateFacturaHistoryAdminStatus`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body,
      }),
    }),
    updateNumFacturaHistory: builder.mutation({
      query: (body) => ({
        url: `/updateFacturaHistoryNumAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        providesTags: ['History'],
        method: 'PUT',
        body,
      }),
    }),
    payOrdersInFactura: builder.mutation({
      query: (body) => ({
        url: `/payOrdersAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    undoPaymentInFactura: builder.mutation({
      query: (body) => ({
        url: `/upPayOrdersAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    getFacturasByClient: builder.query({
      query: (query) => ({
        url: `/getFacturasByClientAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName: query.tradeName,
        },
      }),
      transformResponse: (response) => response.reverse(),
      providesTags: ['History'],
    }),
    importCategories: builder.mutation({
      query: (body) => ({
        url: `/importProductsLanding`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        body,
      }),
    }),
    changeDescription: builder.mutation({
      query: (body) => ({
        url: `/changeDescriptionOrder`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    changeInnerDescription: builder.mutation({
      query: (body) => ({
        url: `/changeInnerDescriptionOrder`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    updatePayValueInFacturaHistoryAdmin: builder.mutation({
      query: (body) => ({
        url: `/updatePayValueInFacturaHistoryAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    getSitesParams: builder.query({
      query: () => ({
        url: `/getSiteParams`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['SiteParams'],
    }),
    getSiteParamsByName: builder.query({
      query: (name) => ({
        url: `/getSiteParamsByName/${name}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    updateSiteParams: builder.mutation({
      query: (body) => ({
        url: `/updateSiteParams`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body,
      }),
    }),
    createSampleFile: builder.mutation({
      query: (body) => ({
        url: `/createSample`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    getSamples: builder.query({
      query: () => ({
        url: `/getSamples`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    getSampleByName: builder.query({
      query: (name) => ({
        url: `/getSampleByName?name=${name}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    updateSample: builder.mutation({
      query: (body) => ({
        url: `/updateSample`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body,
      }),
    }),
    getUniqueCurrentUnits: builder.query({
      query: () => ({
        url: `/getUniqueCurrentUnits`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),
    //register admin
    loginAdmin: builder.mutation({
      query: (body) => ({
        url: `/auth`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    registerAdmin: builder.mutation({
      query: (body) => ({
        url: `/createAccAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    getSiteParamsById: builder.query({
      query: (query) => ({
        url: `/getSiteParamsById`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          _id: query._id,
        },
      }),
    }),

    //personal sales

    getPersonalSales: builder.query({
      query: (tradeName) => ({
        url: `/getClientPersonalSales`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName,
        },
      }),
      providesTags: ['PersonalSales'],
    }),
    addPersonalSale: builder.mutation({
      query: (body) => ({
        url: `/addClientPersonalSales`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),
    updatePersonalSale: builder.mutation({
      query: (body) => ({
        url: `/updateClientPersonalSales`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    updateSpoPricePersonal: builder.mutation({
      query: (body) => ({
        url: `/updateSpoPricePersonal`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body,
      }),
    }),

    getLogs: builder.query({
      query: (params) => ({
        url: `/getLogs`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params,
      }),
    }),

    getZumos: builder.query({
      query: (params) => ({
        url: `/getZumosOperacionAdmin?tradeName=${localStorage.getItem('name') || 'TPO'}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),

    getItemsOperacion: builder.query({
      query: (params) => ({
        url: `/getItemsOperacionAdmin?tradeName=${localStorage.getItem('name') || 'TPO'}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params,
      }),
    }),

    sendConfirmationWA: builder.mutation({
      query: (body) => ({
        url: `/sendProdToDriverTemplates`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    //drivers

    getDrivers: builder.query({
      query: () => ({
        url: `/driverListGet`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
      providesTags: ['Drivers'],
    }),

    driverListCreate: builder.mutation({
      query: (body) => ({
        url: `/driverListCreate`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    driverListUpdate: builder.mutation({
      query: (body) => ({
        url: `/driverListUpdate/${body._id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: {
          name: body.name,
          phone: body.phone,
        },
      }),
    }),

    driverListDelete: builder.mutation({
      query: (id) => ({
        url: `/driverListDelete/${id}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      }),
    }),

    getBuisnessContactByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/getBuisnessContactByTradeName`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName,
        },
      }),
    }),

    getOrderFromText: builder.query({
      query: (query) => ({
        url: `/parseCreateOrderAdmin?data=${encodeURIComponent(query)}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }),
    }),

    sendOrdersToDriverTemplate: builder.mutation({
      query: (body) => ({
        url: `/sendOrdersToDriverTemplates`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    startEditingOrder: builder.mutation({
      query: (body) => ({
        url: `/startEditingOrder`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    finishEditingOrder: builder.mutation({
      query: (body) => ({
        url: `/finishEditingOrder`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    updateItemStatusInOrder: builder.mutation({
      query: (body) => ({
        url: `/changeStatusInProductWithOrder`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    uniteOrdersAdmin: builder.mutation({
      query: (body) => ({
        url: `/uniteOrdersAdmin`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    moveItemFromOrderToOrder: builder.mutation({
      query: (body) => ({
        url: `/moveItemsFromOrderToOrder`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    getProductsWithLowProfit: builder.query({
      query: ({ search, limit }) => ({
        url: `/getProductsWithLowProfit`,
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          search,
          limit,
        },
        method: 'GET',
      }),
    }),

    allReadyItems: builder.mutation({
      query: (body) => ({
        url: `/allReadyItems`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body,
      }),
    }),

    getActualOrdersByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/getOrdersByTradeName`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName,
        },
      }),
    }),

    isInFirstThreeOrdersByTradeName: builder.query({
      query: (tradeName) => ({
        url: `/isInFirstThreeOrdersByTradeName`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
        params: {
          tradeName,
        },
      }),
    }),
  }),
})
