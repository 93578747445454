import React, {useMemo, useState} from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Checkbox, Link, Modal, Switch, Typography} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import {
    findProfit,
    findTotal
} from "../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyItem/OrderChangeTableBodyItem";

import { ReactComponent as Like } from '../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg'
import BuyerProductionListItem from "./BuyerProductionListItem";

const BuyerProductionList = ({statusBlock, setChangeStatus, statuses, mobileScreen, rowStyle, checkBoxStyle, rowStyleMap, switchStyle, statusBackground, selectedItems, allItemsArray, handleTopLevelSelectAlone, handleAllItemsSelect, setSwitchChecker, switchChecker, rows, openRows, toggleRowOpen, handleNestedSelect}) => {

    const [statusModal, setStatusModal] = useState(false)

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead sx={{height: "30px"}}>
                    <TableRow sx={{height: "30px"}}>
                        <TableCell sx={{...rowStyle, padding: "0", textAlign: "center"}}>
                            <Checkbox
                                // Checked if all nested orders are selected
                                sx={checkBoxStyle}
                                checked={selectedItems.length == allItemsArray.length}
                                onChange={(event) => handleAllItemsSelect()}
                            />
                        </TableCell>
                        <TableCell sx={rowStyle}>Status</TableCell>
                        <TableCell sx={rowStyle}>Image</TableCell>
                        <TableCell sx={rowStyle} align="left">Name</TableCell>
                        <TableCell sx={rowStyle} align="left">Pavilion</TableCell>
                        <TableCell sx={rowStyle} align="left">Quantity</TableCell>
                        <TableCell sx={rowStyle} align="left">Price</TableCell>
                        <TableCell sx={rowStyle} align="left">Buyer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allItemsArray.slice().sort((a, b) => new Date(a.date) - new Date(b.date)).map((item) => item.items?.comprador && item.items?.comprador.find(el => JSON.parse(localStorage.getItem("acc")).phoneNumber.replaceAll(" ", "").includes(el.phone.replaceAll(" ", ""))) && (

                        <BuyerProductionListItem key={item._id + "|" + item.items._id} item={item} allItemsArray={allItemsArray}
                                                 statusBackground={statusBackground}
                                                 handleAllItemsSelect={handleAllItemsSelect}
                                                 switchChecker={switchChecker} setSwitchChecker={setSwitchChecker}
                                                 rows={rows} selectedItems={selectedItems}
                                                 handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                                                 toggleRowOpen={toggleRowOpen} openRows={openRows}
                                                 handleNestedSelect={handleNestedSelect}
                                                 rowStyle={rowStyle}
                                                 rowStyleMap={rowStyleMap}
                                                 checkBoxStyle={checkBoxStyle}
                                                 switchStyle={switchStyle}
                                                 statuses={statuses}
                                                 mobileScreen={mobileScreen}
                                                 setChangeStatus={setChangeStatus}
                                                 statusBlock={statusBlock}
                        />


                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BuyerProductionList;