import {
    Box,
    CircularProgress,
    Container,
    TextField,
    Snackbar,
    FormControl,
    Button,
    Checkbox,
    ListItemText,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    useMediaQuery, Modal
} from '@mui/material'


import { ReactComponent as Like } from '../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../assets/svg/Plus.svg'


import { ReactComponent as Granada } from '../../assets/svg/Juice/Pomegranat.svg'
import { ReactComponent as Citrico } from '../../assets/svg/Juice/Citrico.svg'
import { ReactComponent as Lima } from '../../assets/svg/Juice/Lima.svg'
import { ReactComponent as Limon } from '../../assets/svg/Juice/Lemon.svg'
import { ReactComponent as Pomelo } from '../../assets/svg/Juice/Pomelo.svg'
import { ReactComponent as Mandarina } from '../../assets/svg/Juice/Mandarin.svg'
import { ReactComponent as Naranja } from '../../assets/svg/Juice/Naranja.svg'
import { ReactComponent as Jengibre } from '../../assets/svg/Juice/Jengibre.svg'
import { ReactComponent as JengibreLimon } from '../../assets/svg/Juice/JingLemon.svg'






import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { mainApi } from '../../store/services/mainApi'

import ItemsProductionAccordion from "./Switch/Accordion/ItemsProductionAccordion";
import ItemsProductionList from "./Switch/List/ItemsProductionList";
import Stats from "../Stats/Stats";
import filter from "../../assets/png/Table/filter.png";
import calendar from '../../assets/png/Dashboard/calendar.png'

import {ThemeProvider} from "@emotion/react";
import theme from "../../utils/themes/mainTheme";
import StatusesMultiselect from "../Orders/OrderTable/OrderTableHeader/StatusesMultiselect/StatusesMultiselect";
import {DatePicker} from "@mui/x-date-pickers";
import MultipleSelectChip from "../Rcomp/MultipleSelectChip/MultipleSelectChip";
import chocko from "../../assets/png/choco.png";
import prezo from "../../assets/png/prezo.png";
import botton from "../../assets/png/boton.jpg";
import ItemsProductionClient from "./Switch/Client/ItemsProductionClient";




export default function ItemsProduction() {
    useEffect(() => {
        document.title = 'Items Shopping'
    }, [])
    const [selectedDeliveryDates, setSelectedDeliveryDates] = useState([]);
    const [rows, setRows] = useState([]);
    const { data: statusesData, isLoading3 } = mainApi.useGetAllStatusesQuery()
    const { data: drivers } = mainApi.useGetDriversQuery()

    const [statuses, setStatuses] = useState([])
    const [statusModal, setStatusModal] = useState(false)
    const [deliveryDateModal, setDeliveryDateModal] = useState(false)


    useEffect(() => {
        if (statusesData) {
            setStatuses(statusesData.filter((status) => status.type === 'itemsInOrder'))
        }
    }, [statusesData])

    const mobileScreen = useMediaQuery('(max-width: 768px)')
    const [selectedTradeNames, setSelectedTradeNames] = useState([]);
    const [selectedZumos, setSelectedZumos] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);


    const [openRows, setOpenRows] = useState({});
    const [selectedItems, setSelectedItems] = useState([]); // Holds selected items
    const [switchChecker, setSwitchChecker] = useState(false);
    const [switchClientZumos, setSwitchClientZumos] = useState(2);
    const [isSortedByNewest, setIsSortedByNewest] = useState(true);
    const [search, setSearch] = useState('')


    const [buttonFilters, setButtonFilters] = useState({
        Crist: false,
        Plast: false,
        PLM: false,
        PLT: false,
        Granada: false,
        Naranja: false,
        Citrico: false,
        GINGER: false,
        LIMA: false,
        LIMON: false,
        POMELO: false,
        Mandarina: false,
        Jengibre: false
    });


    const toggleButtonFilter = (filterName) => {
        setButtonFilters(prevState => ({
            ...prevState,
            [filterName]: !prevState[filterName]
        }));
    };


    const handleAllItemsSelect = () => {
        if(selectedItems.length == allItemsArray.length){
            setSelectedItems([]);
        }else{
            setSelectedItems(allItemsArray)
        }
    }

    const handleNestedSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemExists = prevItems.some(i =>
                (i._id + i.items._id) === (item._id + item.items._id)
            );

            if (isChecked) {
                // Add item if checked and not already in selectedItems
                if (!itemExists) {
                    return [...prevItems, item];
                }
                return prevItems;
            } else {
                // Remove item if unchecked
                return prevItems.filter(i =>
                    (i._id + i.items._id) !== (item._id + item.items._id)
                );
            }
        });
    };

    const handleTopLevelSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = item.orders.map(order => order._id + order.items._id);

            if (isChecked) {
                // Add all orders from this item if checked
                const newItems = item.orders.filter(order =>
                    !prevItems.some(i => (i._id + i.items._id) === (order._id + order.items._id))
                );
                return [...prevItems, ...newItems];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };

    const handleTopLevelSelectAlone = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = [item._id + item.items._id]

            if (isChecked) {
                // Add all orders from this item if checked
                return [...prevItems, item];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };



// Toggle open state for individual rows
    const toggleRowOpen = (itemId) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [itemId]: !prevOpenRows[itemId]
        }));
    };



    const { data: uniqueZumos, isLoading } = mainApi.useGetItemsOperacionQuery(
        {

        },
        //  { pollingInterval: 5000 },
    )
    console.log(uniqueZumos)



    // const { data: uniqueZumos1, isLoading1 } = mainApi.useGetZumosFirstQuery(
    //     {
    //
    //     },
    //    // { pollingInterval: 5000 },
    // )



    // useEffect(() => {
    //     if(!isLoading){
    //         setUniqueZumos(uniqueZumosPoll)
    //     }
    // }, [isLoading, uniqueZumosPoll]);

    const normalizeText = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();


    useEffect(() => {
        if (!isLoading) {
            const filteredRows = uniqueZumos.map(el => {
                const filteredOrders = el.orders.filter(e => {

                    if(
                        (selectedTradeNames.length === 0 || selectedTradeNames.includes(e.tradeName))
                        && (search.length == 0 || normalizeText(e.items.name).includes(normalizeText(search)) || normalizeText(e.tradeName).includes(normalizeText(search)))
                        && ( selectedZumos.length === 0 ||
                            selectedZumos.some((zumo) =>
                                normalizeText(e.items.name).includes(normalizeText(zumo))
                            ))
                        && ( selectedStatus.length === 0 ||
                            selectedStatus.some((status) =>
                                e.items.status.name == status
                            ))


                    ){

                        const matchesPLM = buttonFilters.PLM ? e.deliveryTime.includes("PLM") : true;
                        const matchesPLT = buttonFilters.PLT ? e.deliveryTime.includes("PLT") : true;
                        const matchesDeliveryDate = selectedDeliveryDates.length
                            ? selectedDeliveryDates.some(date => e.deliveryDate.includes(date))
                            : true;

                        return matchesPLM && matchesPLT && matchesDeliveryDate;
                    }else{
                        return false
                    }
                });

                return filteredOrders.length > 0 ? { ...el, orders: filteredOrders } : null;
            }).filter(el => el !== null);

            const fullyFilteredRows = filteredRows.filter(el => {
                const matchesOtherFilters = Object.keys(buttonFilters).every(filter => {
                    if (buttonFilters[filter] && filter !== 'PLM' && filter !== 'PLT') {
                        return el.orders.some(e => normalizeText(e.items.name).includes(normalizeText(filter)));
                    }
                    return true;
                });

                const matchesSearch = el.orders.some(e =>
                    normalizeText(e.items.name).includes(normalizeText(search)) ||
                    normalizeText(e.tradeName).includes(normalizeText(search))
                );

                return matchesOtherFilters && matchesSearch;
            });

            const sortedRows = fullyFilteredRows.sort((a, b) => {
                const dateA = Math.max(...a.orders.map(e => e.date));
                const dateB = Math.max(...b.orders.map(e => e.date));
                return isSortedByNewest ? dateB - dateA : dateA - dateB;
            });

            setRows(sortedRows);
        }
    }, [uniqueZumos, search, buttonFilters, isLoading, isSortedByNewest, selectedDeliveryDates, selectedTradeNames, selectedZumos, selectedStatus]);



    const fakeRows = useMemo(() => {
        // Шаг 1: Модификация sku для каждого элемента
        const modifiedRows = rows.map(item => ({
            ...item,
            sku: item.sku.replace("plast", "").replaceAll("1", "")
        }));

        // Шаг 2: Объединение элементов с одинаковым sku
        const mergedRows = modifiedRows.reduce((acc, item) => {
            const existingItem = acc.find(i => i.sku === item.sku);

            if (existingItem) {
                // Объединяем свойства для одинаковых sku
                existingItem.orders = existingItem.orders.concat(item.orders);
                existingItem.totalPrice += item.totalPrice;
                existingItem.totalQuantity += item.totalQuantity;
            } else {
                // Если элемента с таким sku еще нет, добавляем его в массив
                acc.push({ ...item });
            }

            return acc;
        }, []);

        // Выводим результат в консоль для проверки
        // mergedRows.forEach(e => console.log(e));

        return mergedRows;
    }, [rows]);



    const groupedClients = useMemo(() => {
        // Шаг 1: Группировка заказов по клиентам
        const clientOrders = rows.reduce((acc, row) => {
            row.orders.forEach(order => {
                const clientId = order.userAcc.id;

                if (!acc[clientId]) {
                    acc[clientId] = {
                        clientInfo: {
                            id: clientId,
                            name: order.userAcc.name,
                            tradeName: order.userAcc.tradeName,
                            phone: order.userAcc.phone,
                            email: order.userAcc.email
                        },
                        products: {}
                    };
                }

                // Проход по продуктам в заказе
                const item = order.items;
                const modifiedSku = item.sku.replace("plast", "").replaceAll("1", "");

                if (!acc[clientId].products[modifiedSku]) {
                    acc[clientId].products[modifiedSku] = {
                        ...item,
                        sku: modifiedSku,
                        totalQuantity: 0,
                        totalPrice: 0,
                        orders: []
                    };
                }

                acc[clientId].products[modifiedSku].totalQuantity += item.quantity;
                acc[clientId].products[modifiedSku].totalPrice += item.totalPrice || 0;
                acc[clientId].products[modifiedSku].orders.push(order);
            });

            return acc;
        }, {});

        // Конвертирование объекта в массив с необходимой структурой
        return Object.values(clientOrders).map(client => ({
            ...client,
            products: Object.values(client.products)
        }));
    }, [rows]);


    console.log(groupedClients)

    const allFixedItemsArray = useMemo(() => {
        if(!isLoading){
            return uniqueZumos.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [isLoading, uniqueZumos])


    const allItemsArray = useMemo(() => {
        if(!isLoading){
            return rows.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [rows])




    const toggleDeliveryDateFilter = (date) => {
        setSelectedDeliveryDates(prevDates =>
            prevDates.includes(date)
                ? prevDates.filter(d => d !== date)
                : [...prevDates, date]
        );
    };


    const mock = [
        {
            image: 'Cart',
            title: 'Total',
            value: allItemsArray.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0).toFixed(2),
            color: 'rgb(92, 195, 253)',
        },
        {
            image: 'GreenCart',
            title: 'Still wanted',
            value: allItemsArray.reduce((acc, item) => item.items.sku.includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0).toFixed(2),
            color: 'rgb(0, 171, 85)',
        },
        {
            image: 'RedCart',
            title: 'Ready for pick up',
            value: allItemsArray.reduce((acc, item) => !item.items.sku.includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0).toFixed(2),
            color: 'red',
        },
        // {
        //     image: 'Cog',
        //     title: 'COGS',
        //     value: allItemsArray.reduce((accumulator, currentValue) => parseFloat(currentValue.items.ourPrice * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€",
        //     color: '#0E8C15',
        // },
        // {
        //     image: 'Catalog',
        //     title: 'Catalog Price',
        //     value:  allItemsArray.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€",
        //     color: 'rgb(0, 171, 85)',
        // },
        // {
        //     image: 'Spo',
        //     title: 'Average Price',
        //     value: (allItemsArray.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0) / allItemsArray.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0)).toFixed(2) + "€",
        //     color: 'rgb(92, 195, 253)',
        // },
    ]


    const juiceBackground = (juice) => {
        switch (juice) {
            case 'Granada':
                return <Granada width={20} height={20} />
            case 'Citrico':
                return <Citrico width={20} height={20} />
            case 'Lima':
                return <Lima width={20} height={20} />
            case 'Limon':
                return <Limon width={20} height={20} />
            case 'Pomelo':
                return <Pomelo width={20} height={20} /> //Production
            case 'Mandarina':
                return <Mandarina fill='#6D26E1' width={20} height={20} />
            case 'Naranja':
                return <Naranja width={20} height={20} />
            case 'Jengibre':
                return <Jengibre width={20} height={20} />
            case 'Jengibre20/Limon80':
                return <JengibreLimon width={20} height={20} />
            default:
                return null
        }
    }


    const statusBackground = (status) => {
        switch (status) {
            case 'Ready':
                return <Like width={20} height={20} />
            case 'PROCESSING':
                return <InProgress width={20} height={20} />
            case 'NOT AVAILABLE':
                return '⛔'
            case 'CHECKING':
                return '👁️'
            case 'PRODUCTION':
                return <Production width={20} height={20} /> //Production
            case 'SHOPPING':
                return <Delivery fill='#6D26E1' width={20} height={20} />
            case 'CONGEDOR':
                return <CONGEDOR width={20} height={20} />
            case 'DEVOLUCION':
                return '🔙'
            case 'CONFIRMAR':
                return '👍'
            case 'RESERVED':
                return '📌'
            default:
                return null
        }
    }

    const uniqueProveedors = useMemo(() => {
        if(!isLoading){
            return [...new Set(allItemsArray.map(e => e.items.proveedor))]

        }else{
            return []
        }
    }, [allItemsArray, isLoading]);


    const uniqueDeliveryDates = useMemo(() => {
        if(!isLoading){
            return [...new Set(allItemsArray.map(e => (e.deliveryDate.split(",")[0].replaceAll(", PLT🌙", "").replaceAll(", PLT 🌛", "").replaceAll(", PLM ☀️", ""))))]

        }else{
            return []
        }
    }, [allItemsArray, isLoading]);



    const optionsAutoComplete = useMemo(() => {
        if (!isLoading && allFixedItemsArray.length) {
            const uniqueItems = new Map(); // Используем Map для сохранения уникальных tradeName
            allFixedItemsArray.forEach(el => {
                if (!uniqueItems.has(el.tradeName)) {
                    uniqueItems.set(el.tradeName, { label: el.tradeName, id: el._id });
                }
            });
            return Array.from(uniqueItems.values());
        }
        return [];
    }, [isLoading, allFixedItemsArray]);


    const handleTradeNameChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedTradeNames(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleZumosChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedZumos(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    const [zumosFilter, setZumosFilter] = useState([
        {label: "Granada", emoji: "🍎"},
        {label: "Citrico", emoji: "🍋"},
        {label: "Lima", emoji: "🍈"},
        {label: "Limon", emoji: "🍋"},
        {label: "Pomelo", emoji: "🍊"},
        {label: "Mandarina", emoji: "🍊"},
        {label: "Naranja", emoji: "🍊"},
        {label: "Jengibre", emoji: "🍊"},
        {label: "Jengibre20/Limon80", emoji: "🍊"},
    ])


    const rowStyle = {
        height: "50px",
        backgroundColor: "rgba(23, 99, 24, .902)",
        color: "#fff",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal"
    }

    const rowStyleMap = {
        color: "black",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal",
        background: "#ECFFED"
    }

    const checkBoxStyle = {
        color: "rgb(102, 187, 106)",
        '&.Mui-checked': {
            color: "rgb(102, 187, 106)",
        },
    }

    const switchStyle = {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "rgb(102, 187, 106)",
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "rgb(102, 187, 106)",
        },
    }

    console.log(uniqueProveedors)

    return (
        <Container sx={{
            marginBottom: selectedItems.length && mobileScreen && "150px",
            zIndex: "1",
            maxWidth: {
                xs: '100%', // для маленьких экранов
                sm: '100%', // для экранов шире 600px
                md: '100%', // для экранов шире 960px
                lg: '100%', // для экранов шире 1280px
                xl: '100%' // для экранов шире 1920px
            }
        }}>
            <Box>
                    <Stats mock={mock}/>

                <FormControl style={{display: 'flex'}}>
                    <div style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: "nowrap"
                    }}>


                        <FormControl sx={{ m: 1, width: 200 }} color="success">
                            <InputLabel id="demo-simple-select-label">Grouping</InputLabel>
                            <Select
                                labelId="demo-simple-checkbox-label"
                                id="demo-simple-checkbox"
                                color="success"
                                value={switchClientZumos}
                                onChange={(e) => setSwitchClientZumos(e.target.value)}
                                input={<OutlinedInput label="Grouping" />}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: "200px", // Высота выпадающего меню
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={2}>List</MenuItem>
                                <MenuItem value={1}>Products</MenuItem>
                                <MenuItem value={3}>Client</MenuItem>
                            </Select>
                        </FormControl>

                        {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                            <InputLabel id="demo-multiple-checkbox-label">Filters by clients</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                color="success"
                                multiple
                                value={selectedTradeNames}
                                onChange={handleTradeNameChange}
                                input={<OutlinedInput label="Filters by clients"/>}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: '200px', // Set the desired height for the dropdown menu
                                        },
                                    },
                                }}
                            >
                                {optionsAutoComplete.map((el) => (
                                    <MenuItem key={el.label} value={el.label}>
                                        <Checkbox sx={checkBoxStyle} checked={selectedTradeNames.includes(el.label)}/>
                                        <ListItemText primary={el.label}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                            <InputLabel id="demo-multiple-checkbox-label">Select Fruits</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedZumos} // Массив выбранных Zumos
                                onChange={handleZumosChange} // Обработчик изменений
                                input={<OutlinedInput label="Select Fruits" />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) return "Select Fruits"; // Пустой выбор
                                    return `${selected.length}: ${selected
                                        .map((label) =>
                                            `${zumosFilter.find((el) => el.label === label)?.emoji || ""}  ${label}`
                                        )
                                        .join(", ")}`;
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: "200px", // Устанавливаем высоту меню
                                        },
                                    },
                                }}
                            >
                                {zumosFilter.map((el) => (
                                    <MenuItem key={el.label} value={el.label}>
                                        <Checkbox sx={checkBoxStyle} checked={selectedZumos.includes(el.label)} />
                                        {juiceBackground(el.label)}
                                        <ListItemText sx={{marginLeft: 1}} primary={` ${el.label}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                            <Select
                                labelId="demo-simple-checkbox-label"
                                id="demo-simple-checkbox"
                                color="success"
                                value={
                                    Object.keys(buttonFilters).find((key) =>
                                        ["Crist", "Plast"].includes(key) && buttonFilters[key]
                                    ) || "All" // Находим активный фильтр для Unit
                                }
                                onChange={(e) => {
                                    const selectedValue = e.target.value;

                                    setButtonFilters((prevState) => {
                                        // Если выбран "All", сбрасываем только Unit-фильтры
                                        if (selectedValue === "All") {
                                            return {
                                                ...prevState,
                                                Crist: false,
                                                Plast: false,
                                            };
                                        }

                                        // Устанавливаем выбранный Unit-фильтр в true, остальные сбрасываем
                                        return {
                                            ...prevState,
                                            Crist: selectedValue === "Crist",
                                            Plast: selectedValue === "Plast",
                                        };
                                    });
                                }}
                                input={<OutlinedInput label="Unit" />}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: "200px", // Высота выпадающего меню
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={"Crist"}>Crist</MenuItem>
                                <MenuItem value={"Plast"}>Plast</MenuItem>
                            </Select>
                        </FormControl>}
                        {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                            <InputLabel id="demo-simple-select-label">PLM☀️ / PLT🌙</InputLabel>
                            <Select
                                labelId="demo-simple-checkbox-label"
                                id="demo-simple-checkbox"
                                color="success"
                                value={
                                    Object.keys(buttonFilters).find((key) =>
                                        ["PLM", "PLT"].includes(key) && buttonFilters[key]
                                    ) || "All" // Находим активный фильтр для PLM/PLT
                                }
                                onChange={(e) => {
                                    const selectedValue = e.target.value;

                                    setButtonFilters((prevState) => {
                                        // Если выбран "All", сбрасываем только PLM/PLT-фильтры
                                        if (selectedValue === "All") {
                                            return {
                                                ...prevState,
                                                PLM: false,
                                                PLT: false,
                                            };
                                        }

                                        // Устанавливаем выбранный PLM/PLT-фильтр в true, остальные сбрасываем
                                        return {
                                            ...prevState,
                                            PLM: selectedValue === "PLM",
                                            PLT: selectedValue === "PLT",
                                        };
                                    });
                                }}
                                input={<OutlinedInput label="PLM☀️ / PLT🌙" />}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: "200px", // Высота выпадающего меню
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={"PLM"}>PLM☀️</MenuItem>
                                <MenuItem value={"PLT"}>PLT🌙</MenuItem>
                            </Select>
                        </FormControl>}
                        {!mobileScreen && <FormControl sx={{ m: 1, width: 200 }} color="success">
                            <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                input={<OutlinedInput label="Status" />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) return "Select Fruits";
                                    return `${selected.length}:  ${selected
                                        .map(
                                            (label) =>
                                                ` ${
                                                    label
                                                }`
                                        )
                                        .join(", ")}`;
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: "200px", // Set the desired height for the dropdown menu
                                        },
                                    },
                                }}
                            >
                                {statuses.map((el) => (
                                    <MenuItem key={el.name} value={el.name}>
                                        <Checkbox sx={checkBoxStyle} checked={selectedStatus.includes(el.name)} />
                                        {statusBackground(el.name)}
                                        <ListItemText sx={{marginLeft: 1}} primary={`${el.name}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}

                        <TextField sx={{ m: 1, width: 200 }}  id="outlined-basic" label="Search" variant="outlined" color='success'
                                   onChange={(e) => setSearch(e.target.value)}/>




                        {false && !isLoading && !mobileScreen && uniqueDeliveryDates.sort().map(date => date.split('.')[0][0] == 0 ? date.slice(1) : date).map(date => (
                            <Button
                                key={date}
                                sx={{ m: 1, height: 56 }}
                                color="success"
                                variant={selectedDeliveryDates.includes(date) ? "contained" : "outlined"}
                                onClick={() => toggleDeliveryDateFilter(date)}
                            >
                                {date.split('.')[0] + "." + date.split('.')[1]}
                            </Button>
                        ))}


                        <Modal open={deliveryDateModal} onClose={() => setDeliveryDateModal(false)}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '300px',
                                    maxHeight: '400px',
                                    overflow: 'auto',
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    flexWrap: "wrap"
                                }}
                            >
                        {!isLoading && uniqueDeliveryDates.map(date => (
                            <Button
                                key={date}
                                sx={{ m: 1, height: 56 }}
                                color="success"
                                variant={selectedDeliveryDates.includes(date) ? "contained" : "outlined"}
                                onClick={() => toggleDeliveryDateFilter(date)}
                            >
                                {(date.split('.')[0].length == 1 ? "0" + date.split('.')[0] : date.split('.')[0]) + "." + date.split('.')[1]}
                            </Button>
                        ))}
                            </Box>
                        </Modal>
                        {true && <img onClick={() => setDeliveryDateModal(true)} style={{ cursor: 'pointer', width: "39px" }} src={calendar} alt='filter' />}

                        <Modal open={statusModal} onClose={() => setStatusModal(false)}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '300px',
                                    maxHeight: '400px',
                                    overflow: 'auto',
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                    flexWrap: "wrap"
                                }}
                            >

                                <FormControl sx={{ m: 1, width: 200 }} color="success">
                                    <InputLabel id="demo-multiple-checkbox-label">Filters by clients</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        color="success"
                                        multiple
                                        value={selectedTradeNames}
                                        onChange={handleTradeNameChange}
                                        input={<OutlinedInput label="Filters by clients"/>}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '200px', // Set the desired height for the dropdown menu
                                                },
                                            },
                                        }}
                                    >
                                        {optionsAutoComplete.map((el) => (
                                            <MenuItem key={el.label} value={el.label}>
                                                <Checkbox sx={checkBoxStyle} checked={selectedTradeNames.includes(el.label)}/>
                                                <ListItemText primary={el.label}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 200 }} color="success">
                                    <InputLabel id="demo-multiple-checkbox-label">Select Fruits</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedZumos} // Массив выбранных Zumos
                                        onChange={handleZumosChange} // Обработчик изменений
                                        input={<OutlinedInput label="Select Fruits" />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) return "Select Fruits"; // Пустой выбор
                                            return `${selected.length}: ${selected
                                                .map((label) =>
                                                    `${zumosFilter.find((el) => el.label === label)?.emoji || ""}  ${label}`
                                                )
                                                .join(", ")}`;
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "200px", // Устанавливаем высоту меню
                                                },
                                            },
                                        }}
                                    >
                                        {zumosFilter.map((el) => (
                                            <MenuItem key={el.label} value={el.label}>
                                                <Checkbox sx={checkBoxStyle} checked={selectedZumos.includes(el.label)} />
                                                {juiceBackground(el.label)}
                                                <ListItemText sx={{marginLeft: 1}} primary={` ${el.label}`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 200 }} color="success">
                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                    <Select
                                        labelId="demo-simple-checkbox-label"
                                        id="demo-simple-checkbox"
                                        color="success"
                                        value={
                                            Object.keys(buttonFilters).find((key) =>
                                                ["Crist", "Plast"].includes(key) && buttonFilters[key]
                                            ) || "All" // Находим активный фильтр для Unit
                                        }
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;

                                            setButtonFilters((prevState) => {
                                                // Если выбран "All", сбрасываем только Unit-фильтры
                                                if (selectedValue === "All") {
                                                    return {
                                                        ...prevState,
                                                        Crist: false,
                                                        Plast: false,
                                                    };
                                                }

                                                // Устанавливаем выбранный Unit-фильтр в true, остальные сбрасываем
                                                return {
                                                    ...prevState,
                                                    Crist: selectedValue === "Crist",
                                                    Plast: selectedValue === "Plast",
                                                };
                                            });
                                        }}
                                        input={<OutlinedInput label="Unit" />}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "200px", // Высота выпадающего меню
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={"All"}>All</MenuItem>
                                        <MenuItem value={"Crist"}>Crist</MenuItem>
                                        <MenuItem value={"Plast"}>Plast</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 200 }} color="success">
                                    <InputLabel id="demo-simple-select-label">PLM☀️ / PLT🌙</InputLabel>
                                    <Select
                                        labelId="demo-simple-checkbox-label"
                                        id="demo-simple-checkbox"
                                        color="success"
                                        value={
                                            Object.keys(buttonFilters).find((key) =>
                                                ["PLM", "PLT"].includes(key) && buttonFilters[key]
                                            ) || "All" // Находим активный фильтр для PLM/PLT
                                        }
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;

                                            setButtonFilters((prevState) => {
                                                // Если выбран "All", сбрасываем только PLM/PLT-фильтры
                                                if (selectedValue === "All") {
                                                    return {
                                                        ...prevState,
                                                        PLM: false,
                                                        PLT: false,
                                                    };
                                                }

                                                // Устанавливаем выбранный PLM/PLT-фильтр в true, остальные сбрасываем
                                                return {
                                                    ...prevState,
                                                    PLM: selectedValue === "PLM",
                                                    PLT: selectedValue === "PLT",
                                                };
                                            });
                                        }}
                                        input={<OutlinedInput label="PLM☀️ / PLT🌙" />}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "200px", // Высота выпадающего меню
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={"All"}>All</MenuItem>
                                        <MenuItem value={"PLM"}>PLM☀️</MenuItem>
                                        <MenuItem value={"PLT"}>PLT🌙</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ m: 1, width: 200 }} color="success">
                                    <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        input={<OutlinedInput label="Status" />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) return "Select Fruits";
                                            return `${selected.length}:  ${selected
                                                .map(
                                                    (label) =>
                                                        ` ${
                                                            label
                                                        }`
                                                )
                                                .join(", ")}`;
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "200px", // Set the desired height for the dropdown menu
                                                },
                                            },
                                        }}
                                    >
                                        {statuses.map((el) => (
                                            <MenuItem key={el.name} value={el.name}>
                                                <Checkbox sx={checkBoxStyle} checked={selectedStatus.includes(el.name)} />
                                                {statusBackground(el.name)}
                                                <ListItemText sx={{marginLeft: 1}} primary={`${el.name}`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Box>
                        </Modal>

                        {mobileScreen && <img onClick={() => setStatusModal(true)} style={{ cursor: 'pointer' }} src={filter} alt='filter' />}

                    </div>
                </FormControl>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box>
                        {switchClientZumos === 1 && (
                            <ItemsProductionAccordion
                                allItemsArray={allItemsArray}
                                drivers={drivers}
                                setSelectedItems={setSelectedItems}
                                uniqueProveedors={uniqueProveedors}
                                fakeRows={fakeRows}
                                statusBackground={statusBackground}
                                handleAllItemsSelect={handleAllItemsSelect}
                                switchChecker={switchChecker}
                                setSwitchChecker={setSwitchChecker}
                                rows={rows}
                                selectedItems={selectedItems}
                                handleTopLevelSelect={handleTopLevelSelect}
                                toggleRowOpen={toggleRowOpen}
                                openRows={openRows}
                                handleNestedSelect={handleNestedSelect}
                                rowStyle={rowStyle}
                                rowStyleMap={rowStyleMap}
                                checkBoxStyle={checkBoxStyle}
                                switchStyle={switchStyle}
                            />
                        )}
                        {switchClientZumos === 2 && (
                            <ItemsProductionList
                                allItemsArray={allItemsArray}
                                statusBackground={statusBackground}
                                handleAllItemsSelect={handleAllItemsSelect}
                                switchChecker={switchChecker}
                                setSwitchChecker={setSwitchChecker}
                                rows={rows}
                                selectedItems={selectedItems}
                                handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                                toggleRowOpen={toggleRowOpen}
                                openRows={openRows}
                                handleNestedSelect={handleNestedSelect}
                                rowStyle={rowStyle}
                                rowStyleMap={rowStyleMap}
                                checkBoxStyle={checkBoxStyle}
                                switchStyle={switchStyle}
                                statuses={statuses}
                                mobileScreen={mobileScreen}
                            />
                        )}
                        {switchClientZumos === 3 && (
                            <ItemsProductionClient
                                uniqueProveedors={uniqueProveedors}

                                groupedClient={groupedClients}
                                allItemsArray={allItemsArray}
                                statusBackground={statusBackground}
                                handleAllItemsSelect={handleAllItemsSelect}
                                switchChecker={switchChecker}
                                setSwitchChecker={setSwitchChecker}
                                rows={rows}
                                selectedItems={selectedItems}
                                handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                                toggleRowOpen={toggleRowOpen}
                                openRows={openRows}
                                handleNestedSelect={handleNestedSelect}
                                rowStyle={rowStyle}
                                rowStyleMap={rowStyleMap}
                                checkBoxStyle={checkBoxStyle}
                                switchStyle={switchStyle}
                                statuses={statuses}
                                mobileScreen={mobileScreen}
                            />
                        )}
                    </Box>
                )}
            </Box>
            <Box sx={{
                "&:hover": {
                    opacity: 0.5, // Устанавливаем прозрачность при наведении
                },
            }}>
                {/*<Snackbar*/}
                {/*    sx={{marginTop: 12}}*/}
                {/*    open={selectedItems.length}*/}
                {/*    anchorOrigin={{vertical: 'top', horizontal: 'center'}}*/}
                {/*    message={*/}
                {/*        "Selected: " + selectedItems.length +*/}
                {/*        " Total: " + selectedItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€"*/}
                {/*       }*/}
                {/*>*/}
                {/*</Snackbar>*/}
                {/*<Snackbar
                    sx={{marginTop: 6}}
                    open={selectedItems.length}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    message={
                        "AVG: " + (selectedItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0) / selectedItems.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0)).toFixed(2) + "€" +
                        " COGS: " + selectedItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.ourPrice * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€" +
                        " Profit: " + ((selectedItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0)) - (selectedItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.ourPrice * currentValue.items.quantity) + accumulator, 0))).toFixed(2) + "€"
                      }
                >
                </Snackbar>
                <Snackbar
                    open={selectedItems.length}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    message={
                        "Total: " + selectedItems.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0) +
                        " Plast: " + selectedItems.reduce((acc, item) => item.items.sku.includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0) +
                        " Crist: " + selectedItems.reduce((acc, item) => !item.items.sku.includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0)

                    }
                >
                </Snackbar>*/}
            </Box>

        </Container>
    )
}



