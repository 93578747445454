import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { mainApi } from '../../store/services/mainApi'
import { Modal, Button, TextField, CircularProgress, Box } from '@mui/material'

export default function ChangeStatus({ statusName1, data, statuses, setStatusModal, statusModal, type }) {
  const site = JSON.parse(localStorage.getItem('site'))

  const [createStatusToggle, setCreateStatusToggle] = useState(false)
  const [creationStatusName, setCreationStatusName] = useState('')
  const [creationStatusColor, setCreationStatusColor] = useState('#000000')

  const [changeStatusToggle, setChangeStatusToggle] = useState(false)
  const [changeStatusName, setChangeStatusName] = useState(statusName1)
  const [changeStatusColor, setChangeStatusColor] = useState('#000000')
  const [pickedStatus, setPickedStatus] = useState({})

  const dispatch = useDispatch()
  //order
  const [updateStatus, { isLoading, isSuccess: isSuccessOrder }] = mainApi.useUpdateStatusinOrderMutation()
  //product
  const [updateStatusProduct, { isLoading: isLoadingProduct, isSuccess: isSuccessProduct }] = mainApi.useChangeProductStatusMutation()
  //contact
  // const [updateStatusContact, { isLoading: isLoadingContact, isSuccess: isSuccessContact }] = mainApi.useChangeContactStatusMutation()
  //itemInProduct
  const [changeItems] = mainApi.useChangeItemsInOrderMutation()

  const [changeStatus] = mainApi.useChangeStatusAdminMutation()
  const [createStatus] = mainApi.useCreateStatusMutation()

  const [statusName, setStatusName] = useState(statusName1)

  useEffect(() => {
    if (isSuccessOrder || isSuccessProduct) {
      setStatusModal(false)
    }
  }, [isSuccessOrder, isSuccessProduct])

  const pickStatus = (status) => {
    setChangeStatusName(status.name)
    setChangeStatusColor(status.color)
    setPickedStatus(status)
  }

  async function changeStatusFunc() {
    await changeStatus({
      data: [
        {
          ...pickedStatus,
          name: changeStatusName,
          color: changeStatusColor,
        },
      ],
    })
    dispatch(mainApi.util.invalidateTags(['Statuses']))
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
    setChangeStatusToggle(false)
    setChangeStatusName('')
    setChangeStatusColor('#000000')
  }

  async function updateStatusFunc(crElem) {
    if (type === 'order') {
      await updateStatus({
        data: {
          crElem,
          elem: data,
        },
      })
      dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
      setStatusName(crElem.name)
    }
    if (type === 'products') {
      await updateStatusProduct({
        data: {
          crElem,
          elem: data,
        },
      })
      dispatch(mainApi.util.invalidateTags(['Products']))
      setStatusName(crElem.name)
    }
    if (type === 'contacts') {
      setStatusModal(false)
      alert('Not yet implemented')
      // await updateStatusProduct({
      //   data: {
      //     crElem,
      //     elem: data,
      //   },
      // })
      // dispatch(mainApi.util.invalidateTags(['Products']))
      // setStatusName(crElem.name)
    }
    if (type === '--') {
      setStatusModal(false)
      alert('Not yet implemented')
      // await updateStatusProduct({
      //   data: {
      //     crElem,
      //     elem: data,
      //   },
      // })
      // dispatch(mainApi.util.invalidateTags(['Products']))
      // setStatusName(crElem.name)
    }
    setStatusModal(false)
  }

  async function createStatusFunc() {
    if (type === 'order') {
      await createStatus({
        desc: '',
        name: creationStatusName,
        color: creationStatusColor,
        type: 'orders',
        sites: [site.name],
      })
    }
    if (type === 'products') {
      await createStatus({
        desc: '',
        name: creationStatusName,
        color: creationStatusColor,
        type: 'products',
        sites: [site.name],
      })
    }
    if (type === 'contacts') {
      await createStatus({
        desc: '',
        name: creationStatusName,
        color: creationStatusColor,
        type: 'contacts',
        sites: [site.name],
      })
    }
    if (type === 'itemsInProduct') {
      await createStatus({
        desc: '',
        name: creationStatusName,
        color: creationStatusColor,
        type: 'itemsInProduct',
        sites: [site.name],
      })
    }
    dispatch(mainApi.util.invalidateTags(['Statuses']))
    setCreateStatusToggle(false)
    setCreationStatusName('')
    setCreationStatusColor('#000000')
  }

  const handleCloseStatusModal = () => {
    setStatusModal(false)
  }

  //checking colors

  const isColorLight = (color) => {
    // Remove any leading '#' from the color string
    const hex = color.replace('#', '')

    // Convert the hex color to RGB values
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    // Calculate the relative luminance of the color
    const relativeLuminance = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 255

    // Calculate the contrast ratio between the color and white (1) or black (0)
    const contrastRatioWithWhite = (relativeLuminance + 0.05) / 1.05
    const contrastRatioWithBlack = (relativeLuminance + 0.05) / 0.05

    // Return true if the contrast ratio with white is greater than 3, indicating a light color
    // Return true if the contrast ratio with black is less than 3.5, indicating a light color
    return contrastRatioWithWhite > 3 || contrastRatioWithBlack < 3.5
  }

  return (
    <div>
      {type === 'itemsInProduct' ? <span>...</span> : <span>{statusName ? statusName : 'Add'}</span>}
      <Modal
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        }}
        open={statusModal}
        onClose={handleCloseStatusModal}
        onClick={(event) => event.stopPropagation()}
      >
        <Box
          sx={{
            width: 'max-content',
            p: 4,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '14px 0px 14px 14px',
            border: '0.4px solid #2A2D2F',
            boxShadow: '-14px -3px 7.6px 0px rgba(23, 99, 24, 0.25)',

            maxHeight: '90vh',
            overflow: 'auto',
            overflowX: 'hidden',
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '10px',
                width: 'max-content',
              }}
            >
              {statuses.map((status) => {
                return (
                  <div
                    key={status._id}
                    style={{
                      width: '140px',

                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: status.color,

                      borderRadius: '2px',

                      color: isColorLight(status.color) ? '#FFFFFF' : '#2A2D2F',

                      fontFamily: 'Archivo Black',
                      fontSize: '1.1em',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: 'normal',

                      padding: '7px 0px',
                    }}
                    // className='tableBodyItem__item status'
                    onClick={(e) => {
                      if (changeStatusToggle) {
                        pickStatus(status)
                      } else {
                        updateStatusFunc(status)
                      }
                    }}
                  >
                    <span style={{ width: 'max-content', maxWidth: 'max-content' }}>{status.name}</span>
                  </div>
                )
              })}
              <div
                style={{
                  width: '100px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '13px',

                  padding: '7px 0px',

                  color: '#F5F5F7',

                  border: '0px solid #FF725E',

                  background: '#FF725E',

                  fontFamily: 'Inter',
                  fontSize: '0.8em',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                }}
                // className='tableBodyItem__item status'
                onClick={() => updateStatusFunc({})}
              >
                <span style={{ width: 'max-content' }}>Delete</span>
              </div>
              <div
                style={{
                  width: '100px',
                  cursor: 'pointer',
                  display: changeStatusToggle ? 'none' : 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '13px',

                  border: '0px solid #46AD1E',

                  background: '#46AD1E',

                  color: '#F5F5F7',

                  fontFamily: 'Inter',
                  fontSize: '0.8em',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  padding: '7px 0px',
                }}
                // className='tableBodyItem__item status'
                onClick={() => setCreateStatusToggle(!createStatusToggle)}
              >
                <span>Add new</span>
              </div>
              <div style={createStatusToggle ? { display: 'flex', flexDirection: 'column', gap: '10px', width: '140px' } : { display: 'none' }}>
                <TextField value={creationStatusName} onChange={(e) => setCreationStatusName(e.target.value)} />
                <input
                  value={creationStatusColor}
                  style={{ marginTop: '10px', width: '100%' }}
                  type='color'
                  onChange={(e) => setCreationStatusColor(e.target.value)}
                />
                <Button sx={{ width: '100%', marginTop: '10px' }} variant='contained' color='success' onClick={createStatusFunc}>
                  Create
                </Button>
              </div>
              <div
                style={{
                  display: createStatusToggle ? 'none' : 'flex',
                  width: '100px',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '13px',
                  border: '0px solid #FF725E',

                  background: '#407BFF',

                  color: '#F5F5F7',

                  fontFamily: 'Inter',
                  fontSize: '0.8em',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: 'normal',
                  padding: '7px 0px',
                }}
                // className='tableBodyItem__item status'
                onClick={() => setChangeStatusToggle(!changeStatusToggle)}
              >
                <span>Change</span>
              </div>
              <div style={changeStatusToggle ? { display: 'flex', flexDirection: 'column', width: '140px' } : { display: 'none' }}>
                <span style={{ color: 'red', fontWeight: 'bold', margin: '5px auto' }}>Pick status</span>
                <TextField value={changeStatusName} onChange={(e) => setChangeStatusName(e.target.value)} />
                <input
                  value={changeStatusColor}
                  style={{ marginTop: '10px', width: '100%' }}
                  type='color'
                  onChange={(e) => setChangeStatusColor(e.target.value)}
                />
                <Button sx={{ width: '100%', marginTop: '10px' }} variant='contained' color='success' onClick={changeStatusFunc}>
                  Change
                </Button>
              </div>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  )
}
