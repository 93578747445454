import React, { useState, useEffect } from 'react'
import './Header.scss'
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg'
import useMapOperationIcon from '../../hook/Header/useMapOperationIcon'
import useMapIntegrationIcon from '../../hook/Header/useMapIntegrationIcon'
import { ReactComponent as User } from '../../assets/svg/Profile.svg'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/svg/NewLogo.svg'

import { ReactComponent as HeaderCart } from '../../assets/svg/Header/HeaderCart.svg'
import { ReactComponent as HeaderCatalog } from '../../assets/svg/Header/HeaderCatalog.svg'
import { ReactComponent as HeaderMessages } from '../../assets/svg/Header/HeaderMessages.svg'
import { ReactComponent as HeaderOperacion } from '../../assets/svg/Header/HeaderOperacion.svg'
import { ReactComponent as HeaderTelegram } from '../../assets/svg/Header/HeaderTelegram.svg'
import { ReactComponent as HeaderWhatsApp } from '../../assets/svg/Header/HeaderWhatsApp.svg'
import { ReactComponent as HeaderHolded } from '../../assets/svg/Header/HeaderHolded.svg'
import { ReactComponent as HeaderMonday } from '../../assets/svg/Header/HeaderMonday.svg'
import { ReactComponent as HeaderSAP } from '../../assets/svg/Header/HeaderSAP.svg'
import { ReactComponent as HeaderGoogle } from '../../assets/svg/Header/HeaderGoogle.svg'
import { ReactComponent as HeaderPeople } from '../../assets/svg/Header/HeaderPeople.svg'
import { ReactComponent as HeaderIntegration } from '../../assets/svg/Header/HeaderIntegration.svg'
import { ReactComponent as LogoutIcon } from '../../assets/svg/Header/logoutBtn.svg'
import { Box, Button } from '@mui/material'
import { WhatsApp } from '@mui/icons-material';
import { operations } from '../../constants/Operacions'
import { integrations } from '../../constants/Integrations'

export default function Header() {
  const [active, setActive] = useState('')
  const navigate = useNavigate()

  const [logoutMenu, setLogoutMenu] = useState(false)

  const [burger, setBurger] = useState(false)

  const site = JSON.parse(localStorage.getItem('site'))
  const acc = JSON.parse(localStorage.getItem('acc'))

  useEffect(() => {
    setActive(window.location.pathname.split('/').pop())
  }, [])

  // To block scrolling
  function blockScroll() {
    document.body.style.overflow = 'hidden'
  }

  // To unblock scrolling
  function unblockScroll() {
    document.body.style.overflow = ''
  }

  useEffect(() => {
    if (burger) {
      blockScroll()
    } else {
      unblockScroll()
    }
  }, [burger])

  //open menu

  const [openOperations, setOpenOperations] = useState(false)
  const [openIntegration, setOpenIntegration] = useState(false)

  const handleOpenOperations = () => {
    setOpenOperations(true)
  }

  const handleCloseOperations = () => {
    setOpenOperations(false)
  }
  const handleOpenIntegration = () => {
    setOpenIntegration(true)
  }

  const handleCloseIntegration = () => {
    setOpenIntegration(false)
  }

  const handleLogout = () => {
    setLogoutMenu((prev) => !prev)
  }

  return (
    <header>
      <div className='header'>
        <div className='header__logo'>
          <img src={site?.logo || ''} height={30}  />
          <h1>
            {site?.name || ''} <span>Admin</span>
          </h1>
        </div>
        <div className='header__menu'>
          <input type='checkbox' name='' id=''></input>
          <div className='header__menu__lines' onClick={() => setBurger(!burger)}>
            <span className='line line1'></span>
            <span className='line line2'></span>
            <span className='line line3'></span>
          </div>
          {burger && (
            <div className='header__menu__burger' onClick={() => setBurger(!burger)}>
              <div className='header__menu__burger__head'>
                <div className='header__menu__burger__head__logo'>
                  <Logo height={'28px'} width={'23px'}/>
                  TPO Admin
                </div>
                <div className='header__menu__lines' onClick={() => setBurger(!burger)}>
                  <span className='line line1'></span>
                  <span className='line line2'></span>
                  <span className='line line3'></span>
                </div>
              </div>
              <div className='header__menu__burger__account'>
                {acc ? (
                  <div
                    className='header__menu__burger__account__image'
                    onClick={() => navigate('/admin/profile')}
                  >
                    {acc?.name.split(' ')[0] ? acc?.name.split(' ')[0][0] : null}
                  </div>
                ) : (
                  <User className='header__user__image' onClick={() => navigate('/admin/profile')} />
                )}
                {acc &&
                <div className='header__menu__burger__account__name'>
                  {acc?.name}
                </div>}
              </div>
              <div className='header__menu__burger__body'>
                <div className='header__menu__burger__body__item'>
                  <div className='header__menu__burger__body__item__title'>
                    Overview
                  </div>
                  <div className='header__menu__burger__body__item__lists'>
                    <div 
                      onClick={() => {
                        if (site.avalible.orders) {
                          setActive('orders')
                          navigate('/admin/orders')
                        } else {
                          navigate('/admin/noAccess')
                        }
                      }}
                      className='header__menu__burger__body__item__lists__item'
                    >
                      <HeaderCart height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} />
                      Orders
                    </div>
                    <div 
                      onClick={() => {
                        if (site.avalible.contacts) {
                          setActive('contacts')
                          navigate('/admin/contacts')
                        } else {
                          navigate('/admin/noAccess')
                        }
                      }}
                      className='header__menu__burger__body__item__lists__item'
                    >
                      <HeaderPeople height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} />
                      Contacts
                    </div>
                    <div 
                      onClick={() => {
                        if (site.avalible.catalog) {
                          setActive('catalog')
                          navigate('/admin/catalog')
                        } else {
                          navigate('/admin/noAccess')
                        }
                      }}
                      className='header__menu__burger__body__item__lists__item'
                    >
                      <HeaderCatalog height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} />
                      Catalog
                    </div>
                    <div 
                      // onClick={() => {
                      //   if (site.avalible.orders) {
                      //     setActive('orders')
                      //     navigate('/admin/orders')
                      //   } else {
                      //     navigate('/admin/noAccess')
                      //   }
                      // }}
                      className='header__menu__burger__body__item__lists__item'
                      style={{filter: 'brightness(0.5)', opacity: '0.5'}}
                      title="Coming soon"
                    >
                      <HeaderMessages height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} />
                      Messages
                    </div>
                  </div>
                </div>
                <div className='header__menu__burger__body__item'>
                  <div className='header__menu__burger__body__item__title'>
                    Operations
                  </div>
                  <div className='header__menu__burger__body__item__lists'>
                    {operations.map((el, index) => el.enabled && <div
                      key={'burgerOperation' + index}
                      className='header__menu__burger__body__item__lists__item'
                      style={{
                        opacity: el.disabled ? '0.5' : '1',
                        cursor: el.disabled ? 'not-allowed' : 'pointer',
                        filter: el.disabled ? 'brightness(0.5)' : ''}}
                      title={el.disabled ? 'Coming soon' : ''}
                      onClick={() => {
                        if (el.disabled) return
                        handleCloseOperations()
                        if (el.permission) {
                          setActive('operacion')
                          navigate(el.link)
                        } else {
                          navigate('/admin/noAccess')
                        }
                        setBurger(false)
                      }}
                    >
                      {useMapOperationIcon(el.icon)}
                      {el.name}
                    </div>)}
                  </div>
                </div>
                <div className='header__menu__burger__body__item'>
                  <div className='header__menu__burger__body__item__title'>
                    Integration
                  </div>
                  <div className='header__menu__burger__body__item__lists'>
                    {integrations.map((el, index) => el.enabled && <div
                      key={'integration' + index}
                      onClick={() => {
                        
                      }}
                      className='header__menu__burger__body__item__lists__item'
                      style={{ 
                        opacity: el.disabled ? '0.5' : '1',
                        cursor: el.disabled ? 'not-allowed' : 'pointer',
                        filter: el.disabled ? 'brightness(0.5)' : ''}}
                      title={el.disabled ? 'Coming soon' : ''}
                    >
                      {useMapIntegrationIcon(el.icon)}
                      {el.name}
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={active === 'orders' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              if (site?.avalible?.orders) {
                setActive('orders')
                navigate('/admin/orders')
              } else {
                navigate('/admin/noAccess')
              }
            }}
          >
            <HeaderCart height={30} width={30} />
            Orders
          </div>
          <div
            className={active === 'contacts' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              if (site?.avalible?.contacts) {
                setActive('contacts')
                navigate('/admin/contacts')
              } else {
                navigate('/admin/noAccess')
              }
            }}
          >
            <HeaderPeople height={30} width={30} />
            Contacts
          </div>
          <div
            className={active === 'catalog' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              if (site?.avalible?.catalog) {
                setActive('catalog')
                navigate('/admin/catalog')
              } else {
                navigate('/admin/noAccess')
              }
            }}
          >
            <HeaderCatalog height={30} width={30} />
            Catalog
          </div>
          <div
            className={active === 'messages' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              if (site?.avalible?.operacion?.messages) {
                setActive('messages')
                navigate('/admin/orders')
              } else {
                navigate('/admin/noAccess')
              }
            }}
          >
            <HeaderMessages height={30} width={30} />
            Messages
          </div>
          <div
            style={{ position: 'relative' }}
            className={active === 'operacion' ? 'header__menu__item active' : 'header__menu__item'}
            onMouseEnter={handleOpenOperations}
            onMouseLeave={handleCloseOperations}
          >
            <HeaderOperacion height={30} width={30} />
            <span 
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Operations <ArrowDown className='header__menu__item__icon' stroke='#06C0B0' fill='#06C0B0' />
            </span>
            {openOperations && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '100%',
                  // transform: 'translateX(-50%)',
                  width: '500px',
                  zIndex: '2',
                }}
              >
                <Box
                  sx={{
                    marginTop: '10px',
                    left: '0',
                    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.25)',
                    backgroundColor: 'white',
                    display: 'flex',

                    flexWrap: 'wrap',
                    padding: '20px',
                    borderRadius: '10px',
                    animation: 'slideDown 0.5s ease-in-out',
                  }}
                >
                  <div
                    className='header__menu__item__open__title'
                  >
                    Operations
                  </div>
                  {/* <div
                    onClick={() => {
                      setActive('operacion')
                      navigate('/admin/orders')
                    }}
                    className='header__menu__item__open'
                  >
                    Billing
                  </div> */}
                  <div
                  className='header__menu__item__open__operations'>
                    {operations.map((el, index) => el.enabled && <div
                      key={'operation '+index}
                      title={el.disabled ? 'Coming soon' : el.name}
                      style={{ opacity: el.disabled ? '0.5' : '1' }}
                      onClick={() => {
                        if (el.disabled) return
                        if (el.permission) {
                          setActive('operacion')
                          navigate(el.link)
                        } else {
                          navigate('/admin/noAccess')
                        }
                      }}
                      className='header__menu__item__open'
                    >
                      {useMapOperationIcon(el.icon)}
                      {el.name}
                    </div>)}

                  </div>
                </Box>
              </Box>
            )}
          </div>
          <div
            style={{ position: 'relative' }}
            className={active === 'projects' ? 'header__menu__item active' : 'header__menu__item'}
            onMouseEnter={handleOpenIntegration}
            onMouseLeave={handleCloseIntegration}
          >
            <HeaderIntegration width={30} height={30} />
            <span 
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Integration <ArrowDown className='header__menu__item__icon' stroke='#06C0B0' fill='#06C0B0' />
            </span>
            {openIntegration && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '100%',
                  // transform: 'translateX(-50%)',
                  width: '500px',
                  zIndex: '2',
                }}
              >
                <Box
                  sx={{
                    marginTop: '10px',
                    left: '0',
                    boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.25)',
                    backgroundColor: 'white',
                    display: 'flex',

                    flexWrap: 'wrap',
                    padding: '20px',
                    borderRadius: '10px',
                    animation: 'slideDown 0.5s ease-in-out',
                  }}
                >
                  <div
                    className='header__menu__item__open__title'
                  >
                    Integration
                  </div>
                  {/* <div
                    onClick={() => {
                      setActive('operacion')
                      navigate('/admin/orders')
                    }}
                    className='header__menu__item__open'
                  >
                    Billing
                  </div> */}
                  <div
                  className='header__menu__item__open__operations'>
                    {integrations.map((el, index) => el.enabled && <div
                      key={'operation '+index}
                      title={el.disabled ? 'Coming soon' : el.name}
                      style={{ opacity: el.disabled ? '0.5' : '1' }}
                      onClick={() => {
                        if (el.disabled) return
                        if (el.permission) {
                          setActive('operacion')
                          navigate(el.link)
                        } else {
                          navigate('/admin/noAccess')
                        }
                      }}
                      className='header__menu__item__open'
                    >
                      {useMapIntegrationIcon(el.icon)}
                      {el.name}
                    </div>)}

                  </div>
                </Box>
              </Box>
            )}
          </div>
        </div>
        <div className='header__user'>
          <div className='header__user__lang'>
            <p style={{ color: '#176318' }}>CAT</p>
            <ArrowDown className='header__user__lang__icon' stroke='#176318' fill='#176318' />
          </div>
          {acc ? (
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#176318',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#f0f0f0',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: 'normal',
              }}
              onClick={() => navigate('/admin/profile')}
            >
              {acc?.name.split(' ')[0] ? acc?.name.split(' ')[0][0] : null}
            </div>
          ) : (
            <User className='header__user__image' onClick={() => navigate('/admin/profile')} />
          )}
          <Button
            onClick={() => {
              localStorage.removeItem('password')
              localStorage.removeItem('acc')
              localStorage.removeItem('profileID')
              navigate('/admin/auth')
            }}
            color='error'
            className='header__user__logoutButton'
            sx={{width: 'fit-content'}}
          >
            <LogoutIcon />
          </Button>
        </div>
      </div>
    </header>
  )
}

//setActive('operacion')
//              navigate('/admin/history')
