import * as React from 'react'
import Button from '@mui/material/Button'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Box, DialogActions } from '@mui/material'
import calendar from '../../../assets/png/Dashboard/calendar.png'

function ButtonField(props) {
  const { setOpen, id, disabled, InputProps: { ref } = {}, inputProps: { 'aria-label': ariaLabel } = {} } = props

  return (
    <Button
      variant='outlined'
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      //   sx={{ border: 'none', p: 1 }}
    >
      <img src={calendar} style={{ width: '20px', height: '20px' }} alt='calendar' />
    </Button>
  )
}

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false)

  const handleClear = () => {
    props.onChange(null) // Set the value to null
    setOpen(false) // Close the DatePicker
  }

  return (
    <DatePicker
      slots={{
        ...props.slots,
        field: ButtonField,
        actionBar: (actionsProps) => (
          <Box sx={{ position: 'absolute', right: '10px', bottom: '10px' }}>
            <Button onClick={handleClear} color='error' sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
              Clear
            </Button>
          </Box>
        ),
      }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen },
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}

export default function CustomDatePicker({ value, setValue }) {
  return <ButtonDatePicker label={value == null ? null : value.format('MM/DD/YYYY')} value={value} onChange={(newValue) => setValue(newValue)} />
}
