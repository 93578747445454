import React from 'react';

import { ReactComponent as HeaderTelegram } from '../../assets/svg/Header/HeaderTelegram.svg'
import { ReactComponent as HeaderWhatsApp } from '../../assets/svg/Header/HeaderWhatsApp.svg'
import { ReactComponent as HeaderHolded } from '../../assets/svg/Header/HeaderHolded.svg'
import { ReactComponent as HeaderMonday } from '../../assets/svg/Header/HeaderMonday.svg'
import { ReactComponent as HeaderSAP } from '../../assets/svg/Header/HeaderSAP.svg'
import { ReactComponent as HeaderGoogle } from '../../assets/svg/Header/HeaderGoogle.svg'
import HeaderMakro from '../../assets/png/Header/HeaderMakro.jpg'
import HeaderMercadona from '../../assets/png/Header/HeaderMercadona.jpg'
import HeaderConsentio from '../../assets/png/Header/HeaderConsentio.jpg'
import HeaderAmazon from '../../assets/png/Header/HeaderAmazon.jpg'

const useMapIntegrationIcon = (integration) => {
  switch (integration) {
    case 'HeaderTelegram':
      return <HeaderTelegram width={'30px'} height={'30px'} />
    case 'HeaderWhatsApp':
      return <HeaderWhatsApp width={'30px'} height={'30px'}/>
    case 'HeaderHolded':
      return <HeaderHolded width={'30px'} height={'30px'} />
    case 'HeaderMonday':
      return <HeaderMonday width={'30px'} height={'30px'} />
    case 'HeaderSAP':
      return <HeaderSAP width={'30px'} height={'30px'} />
    case 'HeaderGoogle':
      return <HeaderGoogle width={'30px'} height={'30px'} />
    case 'HeaderMakro':
      return <img src={HeaderMakro} width={'30px'} height={'30px'} />
    case 'HeaderMercadona':
      return <img src={HeaderMercadona} width={'30px'} height={'30px'} />
    case 'HeaderConsentio':
      return <img src={HeaderConsentio} width={'30px'} height={'30px'} />
    case 'HeaderAmazon':
      return <img src={HeaderAmazon} width={'30px'} height={'30px'} />
    default:
      return <></>
  }
}

export default useMapIntegrationIcon