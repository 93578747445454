import React, { useEffect, useMemo, useState } from 'react'
import './Orders.scss'
import Stats from '../Stats/Stats'
import { mainApi } from '../../store/services/mainApi'
import { CircularProgress, useMediaQuery } from '@mui/material'

import OrdersTable from './OrderTable/OrderTable'

export default function Orders() {
  //   title: 'ShipmentOrders',

  //filters
  const [perPage, setPerPage] = useState(50)
  const [searchValue, setSearchValue] = useState('')
  const [status, setStatus] = useState([])

  const [dateRange, setDateRange] = useState('')

  const [tradeNames, setTradeNames] = useState([])

  const [productsStatus, setProductsStatus] = useState('')

  const [partOfDay, setPartOfDay] = useState('')

  const [simpleDeliveryDate, setSimpleDeliveryDate] = useState(null)

  const [searchPlatform, setSearchPlatform] = useState('')

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  useEffect(() => {
    console.info(status)
  }, [status])

  useEffect(() => {
    document.title = 'Orders'
  }, [])

  const mobileView = useMediaQuery('(max-width: 768px)')

  const {
    data: ordersData,
    isFetching: isFetchingOrders,
    isLoading: isLoadingOrders,
  } = mainApi.useGetAllOrdersWithLimitQuery({
    limit: perPage ? perPage : 50,
    tradeName: localStorage.getItem('name') ? localStorage.getItem('name') : 'TPO',
    search: searchValue ? searchValue : '',
    status: status ? status : '',
    platform: searchPlatform ? searchPlatform : '',
    tradeNames: tradeNames ? tradeNames : '',
    deliveryDate:
      startDate || endDate
        ? (startDate ? new Date(startDate.toISOString()).getTime() : '') + '-' + (endDate ? new Date(endDate.toISOString()).getTime() : '')
        : '',
    month: dateRange,
    simpleDeliveryDate: simpleDeliveryDate ? simpleDeliveryDate.format('DD.MM.YYYY') : '',
  })
  // const {
  //   data: allOrders,
  //   isLoading: isLoadingAllOrders,
  //   isFetching: isFetchingAllOrders,
  // } = mainApi.useGetAllOrdersQuery(localStorage.getItem('name') ? localStorage.getItem('name') : 'TPO')

  const {
    data: allOrdersData,
    isLoading: isLoadingStats,
    isFetching: isFetchingStats,
  } = mainApi.useGetStatsOrdersQuery(localStorage.getItem('name') || 'TPO')

  const [mock, setMock] = useState([])

  useEffect(() => {
    if (allOrdersData) {
      console.info(allOrdersData, 'ALL ORDERS DATA')
      setMock([
        {
          image: 'Cart',
          title: 'Total orders',
          value: allOrdersData.totalOrders || 0,
          color: '#5cc3fd ',
        },
        {
          image: 'Cogs',
          title: 'COGS',
          value: allOrdersData.totalCOGS.toFixed(2) + '€' || '0€',
          color: '#0E8C15 ',
        },
        {
          image: 'HandWithMoney',
          title: 'Paid',
          value: allOrdersData.totalPaid.toFixed(2) + '€' || '0€',
          color: '#13CF1E ',
        },
        {
          image: 'Sold',
          title: 'Total Sold',
          value: allOrdersData.totalOrderValue.toFixed(2) + '€' || '0€',
          color: '#0E8C15 ',
        },
        {
          image: 'Money',
          title: 'Estimated Profit',
          value: allOrdersData.totalProfit.toFixed(2) + '€' || '0€',
          color: '#13CF1E ',
        },
        {
          image: 'Debts',
          title: 'Balance (Debts)',
          value: allOrdersData.totalDebt.toFixed(2) + '€' || '0.00€',
          color: '#FF0000 ',
        },
      ])
    }
  }, [allOrdersData])

  const { data: contactsData, isLoading: isLoadingContacts } = mainApi.useGetAllContactsQuery()
  const [orders, setOrders] = useState([])
  const [contacts, setContacts] = useState([])

  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData)
    }
  }, [contactsData])

  useEffect(() => {
    if (ordersData) {
      // setOrders(ordersData.filter((el) => el.tradeName != localStorage.getItem('name')))
      setOrders(ordersData)
      // console.log(
      //   ordersData.filter((el) => el.tradeName === localStorage.getItem('name')),
      //   'orders trobel',
      // )
      // console.log(ordersData)
    }
  }, [ordersData])

  // const modalKeys = ['Copy', 'Export', 'Status', 'New order', 'Payment', 'Add Route', 'Delete', 'Total Sold', 'COGS', 'Estimated Profit', 'Paid']

  // const allOrdersTotal = useMemo(() => {
  //   let total = 0
  //   if (allOrders) {
  //     allOrders.forEach((order) => {
  //       order.items.length > 0 ? order.items.forEach((item) => (total += item.marketPriceCP * (item.quantity || 1))) : (total += 0)
  //     })
  //     return total.toFixed(2) + '€'
  //   } else {
  //     return 0
  //   }
  // }, [allOrders])

  // const cogs = useMemo(() => {
  //   let total = 0
  //   if (allOrders) {
  //     allOrders.forEach((order) => {
  //       order.items.length > 0 ? order.items.forEach((item) => (total += item.ourPrice * (item.quantity || 1))) : (total += 0)
  //     })
  //     return total.toFixed(2) + '€'
  //   } else {
  //     return 0
  //   }
  // }, [allOrders])

  // const profit = useMemo(() => {
  //   let total = 0
  //   if (allOrders) {
  //     allOrders.forEach((order) => {
  //       order.items.length > 0
  //         ? order.items.forEach(
  //             (item) => (total += (item.marketPriceCP * (item.quantity || 1) - item.ourPrice * (item.quantity || 1)) * (1 - item.IVA / 100)),
  //           )
  //         : (total += 0)
  //     })
  //     return total.toFixed(2) + '€'
  //   } else {
  //     return 0
  //   }
  // }, [allOrders])

  // const paid = useMemo(() => {
  //   let total = 0
  //   if (allOrders) {
  //     allOrders.forEach((order) => {
  //       order.paidValue ? (total += +order.paidValue) : (total += 0)
  //     })
  //     return total.toFixed(2) + '€'
  //   } else {
  //     return 0
  //   }
  // }, [allOrders])

  // const balance = useMemo(() => {
  //   let total = 0
  //   if (allOrders) {
  //     allOrders.forEach((order) => {
  //       return parseFloat(order.paidValue)
  //         ? (total +=
  //             order.items
  //               .map((item) => (item.marketPriceCP != '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
  //               .reduce((acc, curr) => acc + curr, 0) - (parseFloat(order.paidValue) || 0))
  //         : (total += order.items
  //             .map((item) => (item.marketPriceCP != '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
  //             .reduce((acc, curr) => acc + curr, 0))
  //     })
  //     return total.toFixed(2) + '€'
  //   }
  // }, [allOrders])

  // const mock = [
  //   {
  //     image: 'Cart',
  //     title: 'Total orders',
  //     value: allOrders ? allOrders.length : 0,
  //     color: '#5cc3fd ',
  //   },
  //   {
  //     image: 'Cogs',
  //     title: 'COGS',
  //     value: cogs,
  //     color: '#0E8C15 ',
  //   },
  //   {
  //     image: 'HandWithMoney',
  //     title: 'Paid',
  //     value: paid,
  //     color: '#13CF1E ',
  //   },
  //   {
  //     image: 'Sold',
  //     title: 'Total Sold',
  //     value: allOrdersTotal,
  //     color: '#0E8C15 ',
  //   },

  //   {
  //     image: 'Money',
  //     title: 'Estimated Profit',
  //     value: profit,
  //     color: '#13CF1E ',
  //   },

  //   {
  //     image: 'Debts',
  //     title: 'Balance (Debts)',
  //     value: balance,
  //     color: '#FF0000 ',
  //   },
  // ]
  // const mockVPF = [
  //   {
  //     image: 'Cart',
  //     title: 'Total orders',
  //     value: allOrders ? allOrders.length : 0,
  //     color: '#5cc3fd ',
  //   },

  //   {
  //     image: 'HandWithMoney',
  //     title: 'Paid',
  //     value: paid,
  //     color: '#13CF1E ',
  //   },
  //   {
  //     image: 'Sold',
  //     title: 'Total Sold',
  //     value: allOrdersTotal,
  //     color: '#0E8C15 ',
  //   },

  //   {
  //     image: 'Debts',
  //     title: 'Balance (Debts)',
  //     value: balance,
  //     color: '#FF0000 ',
  //   },
  // ]

  return isLoadingOrders || isLoadingContacts ? (
    <CircularProgress
      size={200}
      color='success'
      sx={{
        position: 'absolute',
        top: mobileView ? '50%' : '45%',
        left: mobileView ? '30%' : '45%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  ) : (
    <div className='orders'>
      {/* <h2 className='orders__title'>All orders</h2> */}
      <Stats
        // localStorage.getItem('name') == 'VPF' ? mockVPF :
        mock={mock}
        historyLink={'/ordersHistory'}
        isLoading={isLoadingStats}
        isFetching={isFetchingStats}
      />
      <div className='orders__body'>
        <OrdersTable
          data={orders}
          setData={setOrders}
          contacts={contacts}
          perPage={perPage}
          setPerPage={setPerPage}
          isFetchingOrders={isFetchingOrders}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          status={status}
          setStatus={setStatus}
          dateRange={dateRange}
          setDateRange={setDateRange}
          tradeNames={tradeNames}
          setTradeNames={setTradeNames}
          setProductsStatus={setProductsStatus}
          productsStatus={productsStatus}
          searchPlatform={searchPlatform}
          setSearchPlatform={setSearchPlatform}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          partOfDay={partOfDay}
          setPartOfDay={setPartOfDay}
          simpleDeliveryDate={simpleDeliveryDate}
          setSimpleDeliveryDate={setSimpleDeliveryDate}
        />
      </div>
    </div>
  )
}
