import React, { useEffect, useState, useMemo } from 'react'
import '../../Table/Table.scss'
import OrderTableHeader from './OrderTableHeader/OrderTableHeader'
import OrderTableBody from './OrderTableBody/OrderTableBody'
import { orderNumber } from './OrderTableBody/OrderTableBodyItem/OrderTableBodyItem'
import { CircularProgress, Box } from '@mui/material'

export default function OrdersTable({
  btntype,
  setData,
  data,
  contacts,
  perPage,
  setPerPage,
  isFetchingOrders,
  searchValue,
  setSearchValue,
  dateRange,
  setDateRange,
  tradeNames,
  setTradeNames,
  productsStatus,
  setProductsStatus,
  setStatus,
  status,
  searchPlatform,
  setSearchPlatform,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  type,
  partOfDay,
  setPartOfDay,
  simpleDeliveryDate,
  setSimpleDeliveryDate,
}) {
  const [selectedItems, setSelectedItems] = useState([])

  //filters

  //date

  const filtredData = useMemo(() => {
    return data.filter((elem) => {
      // If elem or elem.items is undefined, exclude it
      if (!elem || !elem.items) return false

      // Filter by productsStatus
      if (productsStatus) {
        const { sum, count } = elem.items.reduce(
          (acc, item) => {
            if (item && item.status && item.status._id !== '6584a9da0ca272b8a1c94df4') {
              acc.sum++
            }
            acc.count++
            return acc
          },
          { sum: 0, count: 0 },
        )
        const percentage = count > 0 ? sum / count : 0

        if (productsStatus === 1 && percentage !== 0) return false // No products are ready
        if (productsStatus === 3 && percentage !== 1) return false // All products are ready
        if (productsStatus === 2 && (percentage === 0 || percentage === 1)) return false // Some products are ready
      }

      // Filter by partOfDay
      if (partOfDay) {
        if (!elem.deliveryDate || !elem.deliveryDate.includes(partOfDay)) return false
      }

      // If none of the filters exclude this item, include it
      return true
    })
  }, [data, productsStatus, partOfDay])

  useEffect(() => {
    console.log('partOfDay', partOfDay)
  }, [partOfDay])

  const filtredDataLength = useMemo(() => {
    return filtredData.length
  }, [filtredData])

  const handleChangePerpage = (event) => {
    // if (filtredData.length > event.target.value) {
    setPerPage(event.target.value)
    // } else {
    //   setPerPage(filtredData.length)
    // }
  }

  // useEffect(() => {
  //   if (filtredData.length + 1 < perPage) {
  //     setPerPage(filtredData.length + 1)
  //   }
  // }, [filtredData, perPage])

  return (
    <div className='table'>
      <OrderTableHeader
        btntype={btntype}
        setData={setData}
        data={data}
        setPerPage={setPerPage}
        perPage={perPage}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setSearchPlatform={setSearchPlatform}
        status={status}
        setStatus={setStatus}
        dateRange={dateRange}
        setDateRange={setDateRange}
        tradeNames={tradeNames}
        setTradeNames={setTradeNames}
        handleChangePerpage={handleChangePerpage}
        setSelectedItems={setSelectedItems}
        filtredDataLength={filtredDataLength}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        productsStatus={productsStatus}
        setProductsStatus={setProductsStatus}
        isFetchingOrders={isFetchingOrders}
        type={type}
        setPartOfDay={setPartOfDay}
        partOfDay={partOfDay}
        simpleDeliveryDate={simpleDeliveryDate}
        setSimpleDeliveryDate={setSimpleDeliveryDate}
      />
      <OrderTableBody
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        btntype={btntype}
        contacts={contacts}
        data={filtredData}
        perPage={perPage}
        setPerPage={setPerPage}
        searchValue={searchValue}
      />
      {isFetchingOrders || !data ? (
        <Box sx={{ display: 'flex', position: 'relative' }}>
          <CircularProgress sx={{ position: 'absolute', left: '50%', top: '50%' }} />
        </Box>
      ) : (
        <div
          onClick={() => {
            setPerPage(perPage + 50)
          }}
          className='tableBody__more'
        >
          Load more ...
        </div>
      )}
    </div>
  )
}
