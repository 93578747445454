import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {
  TableFooter,
  TableRow,
  Paper,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Box,
  CircularProgress,
  TextField,
  Modal,
  Button,
} from '@mui/material'
import { mainApi } from '../../store/services/mainApi'
import { useNavigate } from 'react-router'
import lock from '../../assets/svg/BoxIcons/lockIcon.svg'
import { useDispatch } from 'react-redux'

export default function SpoManager() {
  const [rows, setRows] = useState([])

  const { data, isLoading } = mainApi.useGetSpoProductsFromContactsQuery()
  const [updateSpoPrice] = mainApi.useUpdateSpoPricePersonalMutation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (data) {
      console.log(data)
      setRows(data)
    }
  }, [data])

  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [openRows, setOpenRows] = useState({}) // Для отслеживания открытых строк

  const [search, setSearch] = useState('')
  const [spoPriceChanges, setSpoPriceChanges] = useState({}) // Track changes to spoPrice

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === 'asc'
    setOrder(isAscending ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearchChange = (event) => {
    event.stopPropagation()
    setSearch(event.target.value) // Updating search state
  }

  const handleSpoPriceChange = (clientId, newPrice, event, sku) => {
    event.stopPropagation()
    event.preventDefault()

    setSpoPriceChanges((prev) => ({
      ...prev,
      [clientId + sku]: newPrice,
    }))
  }

  const toggleRowOpen = (rowId) => {
    setOpenRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }))
  }

  const saveSpoPrice = async (row) => {
    const changesForRow = Object.entries(spoPriceChanges)
      .filter(([key]) => key.includes(row.sku))
      .map(([key, value]) => {
        const clientId = key.replace(row.sku, '')
        return { clientId, newPrice: parseFloat(value) }
      })

    if (changesForRow.length === 0) return

    try {
      const user = JSON.parse(localStorage.getItem('acc'))
      const currentDate = new Date().toLocaleDateString('en-GB') // Формат DD.MM.YYYY

      await Promise.all(
        changesForRow.map(({ clientId, newPrice }) =>
          updateSpoPrice({
            clientId,
            sku: row.sku,
            newSpoPrice: newPrice,
            updatedBy: user.name,
            updatedAt: currentDate,
          }).unwrap(),
        ),
      )

      alert('SPO Price updated successfully!')
      setSpoPriceChanges((prev) => {
        const updated = { ...prev }
        Object.keys(updated).forEach((key) => {
          if (key.includes(row.sku)) delete updated[key]
        })
        return updated
      })

      dispatch(mainApi.util.invalidateTags(['PersonalSalesList']))
    } catch (error) {
      console.error('Failed to update SPO Price:', error)
      alert('Failed to update SPO Price')
    }
  }

  // eslint-disable-next-line react/display-name
  function Row({ row, open, toggleRowOpen, handleSpoPriceChange, spoPriceChanges, saveSpoPrice }) {
    const inputRefs = useRef({})

    useEffect(() => {
      Object.keys(spoPriceChanges).forEach((key) => {
        const inputRef = inputRefs.current[key]
        if (inputRef) {
          inputRef.focus()
        }
      })
    }, [spoPriceChanges])

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell sx={{ width: '24px' }}>
            <IconButton aria-label='expand row' size='small' onClick={() => toggleRowOpen(row.sku)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th' scope='row' sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={row.img} style={{ width: '50px', height: '50px' }} alt='preview' />
            {row.name}
          </TableCell>
          <TableCell>{row.sku}</TableCell>
          <TableCell>{row.marketPriceCP}</TableCell>
          <TableCell>
            <Button variant='contained' color='primary' size='small' onClick={() => saveSpoPrice(row)}>
              Save
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant='h6' gutterBottom component='div'>
                  Clients
                </Typography>
                <Table size='small' aria-label='clients'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>TradeName</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.clients.map((client) => (
                      <TableRow key={client.client_id + row.sku}>
                        <TableCell sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                          <img src={client.client_image} style={{ width: '50px', height: '50px' }} alt='preview' />
                          {client.client_name}
                        </TableCell>
                        <TableCell>
                          <a target='_blank' rel='noreferrer' href={`/admin/contacts/${client.client_id}`}>
                            {client.client_tradeName}
                          </a>
                        </TableCell>

                        <TableCell>
                          <TextField
                            size='small'
                            value={spoPriceChanges[client.client_id + row.sku] || client.spoPrice}
                            onChange={(event) => handleSpoPriceChange(client.client_id, event.target.value, event, row.sku)}
                            sx={{ width: '100px' }}
                            inputRef={(el) => (inputRefs.current[client.client_id + row.sku] = el)}
                          />
                        </TableCell>
                        <TableCell>
                          {client.updatedAt ? (client.updatedAt !== 'Unknown' ? client.updatedAt.split('/').join('.') + ' ' : null) : null}
                          {client.updatedBy ? (client.updatedBy !== 'Unknown' ? client.updatedBy : null) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  Row.propTypes = {
    row: PropTypes.shape({
      name: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
      marketPrice: PropTypes.number.isRequired,
      spoPrice: PropTypes.number.isRequired,
      clients: PropTypes.arrayOf(
        PropTypes.shape({
          client_id: PropTypes.string.isRequired,
          client_name: PropTypes.string.isRequired,
          ourPrice: PropTypes.number.isRequired,
          quantity: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgba(23, 99, 24, 0.9019607843)',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  // Filter the rows based on the search query
  const filteredRows = rows.filter((row) => {
    const searchLower = search.toLowerCase()

    // Check if any of the clients match the search term in client_name or client_tradeName
    const clientsMatch = row.clients.some(
      (client) => client.client_name.toLowerCase().includes(searchLower) || client.client_tradeName.toLowerCase().includes(searchLower),
    )

    // Return true if any of the conditions match
    return row.name.toLowerCase().includes(searchLower) || row.sku.toLowerCase().includes(searchLower) || clientsMatch
  })

  const sortedRows = [...filteredRows].sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1
    }
    return a[orderBy] > b[orderBy] ? -1 : 1
  })

  const paginatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  //lock
  const [password, setPassword] = useState('')
  const [openLock, setOpenLock] = useState(false)
  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const handleShow = () => {
    if (password === '750') {
      setShow(!show)
      setOpenLock(!openLock)
    } else {
      alert('Wrong password')
    }
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Box>
      <Modal open={openLock} onClose={() => setOpenLock(false)} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box
          sx={{
            position: 'absolute',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <TextField id='outlined-basic' label='Password' variant='outlined' onChange={(e) => setPassword(e.target.value)} value={password} />
          <Button variant='contained' color='success' onClick={handleShow}>
            Show
          </Button>
        </Box>
      </Modal>
      {!show && (
        <Modal open={!show}>
          <Box>
            <img
              height={200}
              width={200}
              src={lock}
              alt='lock'
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                filter: 'none',
                zIndex: 9999,
              }}
              onClick={() => setOpenLock(!openLock)}
            />
            <Button
              variant='contained'
              color='success'
              sx={{
                cursor: 'pointer',
                width: '300px',
                height: '50px',
                position: 'absolute',
                top: '5%',
                left: '50%',
                fontSize: '20px',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={() => navigate('/')}
            >
              Return
            </Button>
          </Box>
        </Modal>
      )}
      <TableContainer sx={{ filter: show ? 'none' : 'blur(5px)' }} component={Paper}>
        {/* Search input */}
        <TextField
          id='outlined-basic'
          label='Search'
          size='small'
          variant='outlined'
          sx={{
            m: 0,
            p: 0,
            width: '120px',
            position: 'absolute',
            zIndex: 1,
            left: '270px',
            top: '60px',
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
          onChange={handleSearchChange} // Only change search state
          value={search} // Keep the value in sync with state
        />
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={(e) => handleRequestSort(e, 'name')}
                >
                  Name
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>SKU</StyledTableCell>
              <StyledTableCell>Market Price</StyledTableCell>
              <StyledTableCell>SPO Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row) => (
              <Row
                key={row.sku}
                row={row}
                open={openRows[row.sku] || false}
                toggleRowOpen={toggleRowOpen}
                handleSpoPriceChange={handleSpoPriceChange}
                spoPriceChanges={spoPriceChanges}
                saveSpoPrice={saveSpoPrice}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}
