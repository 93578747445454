import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, TextField, Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { mainApi } from '../../store/services/mainApi'

const LowProfit = () => {
  const [search, setSearch] = useState('')
  const [limit, setLimit] = useState(10)

  const [searchForReq, setSearchForReq] = useState('')
  const [limitForReq, setLimitForReq] = useState(10)

  const { data, isLoading, isFetching } = mainApi.useGetProductsWithLowProfitQuery(
    { search: searchForReq, limit: limitForReq },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const [products, setProducts] = useState([])

  const handleSearchChange = (event) => setSearch(event.target.value)
  const handleLimitChange = (event) => setLimit(event.target.value)

  useEffect(() => {
    console.log(data)
    setProducts(data)
  }, [data])

  return (
    <Box>
      <Box mb={3}>
        <TextField label='Search' variant='outlined' value={search} onChange={handleSearchChange} />
        <TextField label='Limit' variant='outlined' type='number' value={limit} onChange={handleLimitChange} style={{ marginLeft: '10px' }} />
        <Button
          variant='contained'
          onClick={() => {
            setSearchForReq(search)
            setLimitForReq(limit)
          }}
          style={{ marginLeft: '10px' }}
        >
          Search
        </Button>
      </Box>
      {isLoading || isFetching ? (
        <CircularProgress color='success' style={{ margin: 'auto' }} />
      ) : (
        products &&
        products.map((product) => (
          <Box key={product.sku} mb={2}>
            <img src={product.img} alt={product.name} width={100} height={100} />
            <Typography variant='h6'>{product.name}</Typography>
            <Typography variant='subtitle1'>SKU: {product.sku}</Typography>
            <Typography variant='subtitle2'>IVA: {product.IVA}%</Typography>
            <Typography variant='subtitle1'>Low profit in {product.orders.length} orders</Typography>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Orders</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {product.orders.map((order) => (
                  <Grid sx={{ borderBottom: '1px solid #ccc' }} container spacing={2} key={order.orderId} mb={1}>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        <strong>Order ID:</strong>{' '}
                        <a target='_blank' rel='noreferrer' href={`/admin/orders/${order.orderId}`}>
                          {order.orderId}
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>
                        <strong>Market Price:</strong> ${order.marketPrice}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>
                        <strong>Our Price:</strong> ${order.ourPrice}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>
                        <strong>Market Price CP:</strong> ${order.marketPriceCP}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='body2'>
                        <strong>Our Price CP:</strong> ${order.ourPriceCP}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        <strong>Profit Percent:</strong> {order.profitPercent}%
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        <strong>TradeName:</strong> {order.tradeName}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))
      )}
    </Box>
  )
}

export default LowProfit
