export const integrations = [
  {
    link: 'Telegram',
    name: 'Telegram',
    icon: 'HeaderTelegram',
    enabled: true,
    disabled: true,
  },
  {
    link: 'WhatsApp',
    name: 'WhatsApp',
    icon: 'HeaderWhatsApp',
    enabled: true,
    disabled: true,
  },
  {
    link: 'Holded',
    name: 'Holded',
    icon: 'HeaderHolded',
    enabled: true,
    disabled: true,
  },
  {
    link: 'Monday',
    name: 'Monday',
    icon: 'HeaderMonday',
    enabled: true,
    disabled: true,
  },
  {
    link: 'SAP',
    name: 'SAP',
    icon: 'HeaderSAP',
    enabled: true,
    disabled: true,
  },
  {
    link: 'Google',
    name: 'Google',
    icon: 'HeaderGoogle',
    enabled: true,
    disabled: true,
  },
  {
    link: 'Makro',
    name: 'Makro',
    icon: 'HeaderMakro',
    enabled: true,
    disabled: true,
  },
  {
    link: 'Mercadona',
    name: 'Mercadona',
    icon: 'HeaderMercadona',
    enabled: true,
    disabled: true,
  },
  {
    link: 'Consentio',
    name: 'Consentio',
    icon: 'HeaderConsentio',
    enabled: true,
    disabled: true,
  },
  {
    link: 'Amazon',
    name: 'Amazon',
    icon: 'HeaderAmazon',
    enabled: true,
    disabled: true,
  },
]