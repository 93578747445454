import React, { useMemo, useEffect, useState } from 'react'
import '../../../Table/TableHeader/TableHeader.scss'
import SearchIcon from '../../../../assets/svg/Search.svg'
import { FormControl, MenuItem, Select, InputLabel, CircularProgress, Modal, Box, Button, TextField, Autocomplete } from '@mui/material'
import { ReactComponent as Telegram } from '../../../../assets/svg/Telegram.svg'
import { ReactComponent as Whatsapp } from '../../../../assets/svg/Whatsapp.svg'
import { ReactComponent as Browser } from '../../../../assets/svg/Browser.svg'
import { ReactComponent as Instagram } from '../../../../assets/svg/Instagram.svg'
import { ReactComponent as BotoBot } from '../../../../assets/svg/BotoBot.svg'

import botton from '../../../../assets/png/boton.jpg'
import chocko from '../../../../assets/png/choco.png'
import prezo from '../../../../assets/png/prezo.png'

import filter from '../../../../assets/png/Table/filter.png'
import print from '../../../../assets/png/Table/print.png'

import { mainApi } from '../../../../store/services/mainApi'
import { ThemeProvider } from '@emotion/react'
import theme from '../../../../utils/themes/mainTheme'
import OrdersModal from '../../OrdersModal/OrdersModal'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'

import MultipleSelectChip from '../../../Rcomp/MultipleSelectChip/MultipleSelectChip'
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import StatusesMultiselect from './StatusesMultiselect/StatusesMultiselect'
import CustomDatePicker from '../../../Rcomp/CustomDatePicker/CustomDatePicker'

export default function OrderTableHeader({
  btntype,
  setSearchPlatform,
  setData,
  perPage,
  data,
  setSearchValue,
  searchValue,
  setPerPage,
  status,
  setStatus,
  dateRange,
  setDateRange,
  tradeNames,
  setTradeNames,
  handleChangePerpage,
  setSelectedItems,
  filtredDataLength,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  productsStatus,
  setProductsStatus,
  isFetchingOrders,
  type,
  partOfDay,
  setPartOfDay,
  simpleDeliveryDate,
  setSimpleDeliveryDate,
}) {
  const { data: tradeNamesData, isLoading: isLoadingTradeNames } = mainApi.useGetTradeNamesFromContactsQuery()

  const mobileShow = useMediaQuery('(max-width: 768px)')

  const [showSearch, setShowSearch] = useState(true)

  const location = useLocation()

  const dispatch = useDispatch()

  const [mermaModal, setMermaModal] = useState(false)
  const [searchMerma, setSearchMerma] = useState('')

  const today = dayjs()
  const [changableDate, setChangableDate] = useState(today)
  useEffect(() => {
    console.log(changableDate, 'changableDate')
  }, [changableDate])

  const [searchText, setSearchText] = useState('')

  const searchHandler = (inputText) => {
    if (searchText === inputText.substr(-1)) {
      setSearchValue(inputText.substr(-1))
    } else {
      setSearchText(inputText)
      setSearchValue(inputText)
    }
  }

  const [createOrder, { isError, isLoading }] = mainApi.useCreateNewOrderMutation()
  const { data: contactData, isLoading: isLoadingContacts } = mainApi.useGetContactsByIdQuery(location.pathname.split('/').pop(), {
    skip: !location.pathname.split('/').pop().split('').length < 15,
  })
  const { data: statusesData, isLoading: isLoadingStatus } = mainApi.useGetAllStatusesQuery()

  const { data: mercaUser, isLoading: isLoadingMercaUser } = mainApi.useGetContactsByIdQuery('663a0484a20a70b531a72bb3')

  const {
    data: mermaProducts,
    isLoading: isLoadingMermaProducts,
    isFetching: isFetchingMerma,
  } = mainApi.useGetProductsLimitQuery(
    {
      limit: 50,
      search: searchMerma,
      sku: searchMerma,
    },
    {
      skip: !mermaModal,
    },
  )

  const [contact, setContact] = useState({})
  const [open, setOpen] = useState(false)

  //dilterstatusesmodal

  const [statusModal, setStatusModal] = useState(false)
  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData.filter((status) => status.type === 'orders'))
    }
    console.log(statuses, 'statuses')
  }, [statusesData])

  ////////

  useEffect(() => {
    if (contactData) {
      setContact(contactData)
    }
  }, [contactData])
  const createNewOrderInChangeOrder = async () => {
    const body = {
      data: {
        items: [],
        date: Date.now(),
        type: 'browser',
        status: {},
        order: 'CP',
        paidValue: 0,
        userAcc: contact,
      },
    }
    await createOrder(body)
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
  }

  //merma multiply

  const [selectedMerma, setSelectedMerma] = useState([])

  const addQuantity = (item) => {
    // Проверяем, есть ли уже объект в массиве
    const exists = selectedMerma.find((merma) => merma.id === item.id)

    if (exists) {
      // Если объект уже есть, увеличиваем количество
      setSelectedMerma(selectedMerma.map((merma) => (merma.id === item.id ? { ...merma, quantity: merma.quantity + 1 } : merma)))
    } else {
      // Если объекта нет, добавляем его в массив с начальным количеством 1
      setSelectedMerma([...selectedMerma, { ...item, quantity: 1 }])
    }
  }

  const removeQuantity = (item) => {
    // Проверяем, есть ли объект в массиве
    const exists = selectedMerma.find((merma) => merma.id === item.id)

    if (exists && exists.quantity === 1) {
      // Если количество объекта равно 1, удаляем объект из массива
      setSelectedMerma(selectedMerma.filter((merma) => merma.id !== item.id))
    } else if (exists) {
      // Если количество больше 1, уменьшаем количество
      setSelectedMerma(selectedMerma.map((merma) => (merma.id === item.id ? { ...merma, quantity: merma.quantity - 1 } : merma)))
    }
  }

  const openMermaModal = () => {
    setMermaModal(true)
  }

  const closeMermaModal = () => {
    setMermaModal(false)
  }

  const createMerca = async () => {
    const body = {
      data: {
        items: selectedMerma.map((merma) => {
          return {
            ...merma,
            marketPriceCP: merma?.ourPriceCP || merma?.ourPrice || 0,
            status: {},
            quantity: parseFloat(merma?.quantity || 1),
            quantityOrder: parseFloat(merma?.quantity || 1),
          }
        }),
        date: Date.now(),
        type: 'browser',
        status: {},
        order: 'CP',
        paidValue: 0,
        tradeName: localStorage.getItem('name') || 'TPO',
        userAcc: mercaUser,
      },
    }
    await createOrder(body)
    setSelectedMerma([])
    setMermaModal(false)
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
  }

  useEffect(() => {
    if (contact) {
      console.log(contact, 'contact')
    }
  }, [contact])

  const platforms = useMemo(() => {
    const uniquePlatforms = new Set(data.map((elem) => elem.realType || elem.type))
    return Array.from(uniquePlatforms)
  })

  //filter by tradeName
  const tradeNamesUnique = useMemo(() => {
    const uniqueTradeNames = new Set(data.map((elem) => elem?.tradeName))
    //to format {name: 'someName', value: 'someValue'}
    return Array.from(uniqueTradeNames)
  }, [data])

  const [platform, setPlatform] = useState('')
  // const [contactPlatform, setContactPlatform] = React.useState('')
  // const [type, setType] = React.useState('')

  return (
    <div className='tableHeader'>
      <div className='tableHeader__title'>
        <p style={{ marginRight: '10px' }}>
          1-{perPage} of {filtredDataLength} items.
        </p>
        {btntype === 'ordersChange' ? (
          <div style={{ marginRight: '10px' }} className='tableHeader__body__btn active rightSide' onClick={createNewOrderInChangeOrder}>
            {isLoading ? <CircularProgress size={20} /> : 'New order'}
          </div>
        ) : (
          <div style={{ marginRight: '10px' }} className='tableHeader__body__btn active rightSide' onClick={() => setOpen(true)}>
            {isLoading ? <CircularProgress size={20} /> : 'New order'}
          </div>
        )}

        {type == 'shipment' ? (
          <div style={{ marginRight: '10px' }} className='tableHeader__body__btn active rightSide' onClick={openMermaModal}>
            {isLoading ? <CircularProgress size={20} /> : 'Merma'}
          </div>
        ) : null}

        <ThemeProvider theme={theme}>
          <FormControl sx={{ minWidth: 120, height: '42px' }}>
            <InputLabel sx={{ fontFamily: 'Inter', color: '#176318e6', '&.Mui-focused': { color: '#06c0b0' } }}>Rows Quantity</InputLabel>
            <Select
              onChange={(e) => handleChangePerpage(e)}
              label='Rows Quantity'
              className='select'
              renderValue={() => perPage}
              value={perPage}
              size='small'
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={150}>150</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </FormControl>
        </ThemeProvider>
        {/*filters for orders */}
        <div onClick={() => setStatusModal(true)} style={{ margin: '8px' }} className='tableHeader__body__btn'>
          Filters
        </div>
        <Modal open={statusModal} onClose={() => setStatusModal(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '300px',
              maxHeight: '400px',
              overflow: 'auto',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {/* <p>Status</p> */}
            {isLoadingStatus && statuses ? (
              <CircularProgress />
            ) : (
              <ThemeProvider theme={theme}>
                <FormControl sx={{ minWidth: 120 }}>
                  {/* <InputLabel sx={{ fontFamily: 'Inter', color: '#176318e6', '&.Mui-focused': { color: '#06c0b0' } }}>Status</InputLabel> */}
                  <StatusesMultiselect
                    statuses={
                      window.location.pathname === '/admin/ordersHistory'
                        ? statuses
                        : statuses.filter((status) => status.name !== 'Emitido' && status.name !== 'Emitido P')
                    }
                    values={status}
                    setValues={setStatus}
                    label='Status'
                  />
                  {/* <Select
                    label='Status'
                    fullWidth
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value)
                      setSelectedItems([])
                      setStatusModal(false)
                    }}
                  >
                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value=' '>Add(NEW)</MenuItem>
                    {statuses.map((elem) => (
                      <MenuItem
                        key={elem.id}
                        sx={{
                          backgroundColor: elem.color,
                        }}
                        value={elem.name}
                      >
                        {elem.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                </FormControl>
                <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
                  {/* <DatePicker label='Filter by creation date' value={changableDate} onChange={(newValue) => setChangableDate(newValue)} /> */}
                  <DatePicker
                    sx={{
                      width: '60%',
                    }}
                    views={['month']}
                    label='Month'
                    // minDate={dayjs}
                    // maxDate={new Date('2023-06-01')}
                    value={changableDate}
                    onChange={(newValue) => setChangableDate(newValue)}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                  <Button
                    sx={{
                      width: '15%',
                    }}
                    variant='outlined'
                    color='success'
                    onClick={(e) => {
                      e.stopPropagation()
                      setDateRange(changableDate.$M + 1)
                      setStatusModal(false)
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    sx={{
                      width: '15%',
                    }}
                    color='error'
                    variant='outlined'
                    onClick={() => {
                      setDateRange('')
                      setSelectedItems([])

                      setStatusModal(false)
                    }}
                  >
                    Clear
                  </Button>
                </FormControl>
                <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
                  <DatePicker
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    sx={{ width: 150 }}
                    label='Start date'
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                    format='DD.MM.YY'
                  />
                  <DatePicker
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                    }}
                    sx={{ width: 150 }}
                    label='End date'
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                    format='DD.MM.YY'
                  />
                </FormControl>
                {/* <p>TradeNames</p> */}
                <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
                  {isLoadingTradeNames ? (
                    <CircularProgress />
                  ) : (
                    <MultipleSelectChip setValues={setTradeNames} values={tradeNames} names={tradeNamesData} label={'Filter by users'} />
                  )}
                </FormControl>
                <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
                  <InputLabel id='productsStatus' sx={{ fontFamily: 'Inter', color: '#176318e6', '&.Mui-focused': { color: '#06c0b0' } }}>
                    Order status
                  </InputLabel>

                  <Select
                    id='productsStatus'
                    labelId='productsStatus'
                    sx={{ width: '60%' }}
                    label='Order status'
                    value={productsStatus}
                    onChange={(e) => setProductsStatus(e.target.value)}
                  >
                    <MenuItem color='info' value=''>
                      All
                    </MenuItem>
                    <MenuItem color='success' value={1}>
                      Ready
                    </MenuItem>
                    <MenuItem color='error' value={3}>
                      Not Ready
                    </MenuItem>
                    <MenuItem color='warning' value={2}>
                      Partial
                    </MenuItem>
                  </Select>
                  {/* <Button sx={{ width: '40%' }} variant='outlined' color='success' onClick={() => setProductsStatus(1)}>
                    Ready
                  </Button>
                  <Button sx={{ width: '40%' }} variant='outlined' color='error' onClick={() => setProductsStatus(0)}>
                    Not Ready
                  </Button> */}
                </FormControl>
                {/* <FormControl fullWidth sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'space-between' }}>
                  <Button sx={{ width: '40%' }} variant='outlined' color='warning' onClick={() => setProductsStatus(0.5)}>
                    Partical
                  </Button>
                  <Button sx={{ width: '40%' }} variant='outlined' color='info' onClick={() => setProductsStatus('')}>
                    All
                  </Button>
                </FormControl> */}
              </ThemeProvider>
            )}
            {mobileShow && (
              <ThemeProvider theme={theme}>
                <FormControl sx={{ minWidth: 120, height: '42px' }}>
                  <InputLabel sx={{ fontFamily: 'Inter', color: '#176318e6', '&.Mui-focused': { color: '#06c0b0' } }}>Rows Quantity</InputLabel>
                  <Select
                    onChange={(e) => handleChangePerpage(e)}
                    label='Rows Quantity'
                    className='select'
                    renderValue={() => perPage}
                    value={perPage}
                    size='small'
                  >
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={150}>150</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>
            )}
            {mobileShow && (
              <FormControl sx={{ minWidth: 155, position: 'relative' }}>
                <InputLabel
                  sx={{
                    marginLeft: '32px',
                    position: 'absolute',
                    top: '-7px',
                    fontFamily: 'Inter',
                    color: '#176318e6',
                    '&.Mui-focused': { color: '#06c0b0' },
                  }}
                >
                  Platform
                </InputLabel>
                <Select
                  onChange={(e) => {
                    setPlatform(e.target.value)
                    setSearchPlatform(e.target.value)
                    setSelectedItems([])
                  }}
                  value={platform}
                  size='small'
                >
                  <MenuItem value=''>All</MenuItem>
                  <MenuItem value='choco'>
                    <img src={chocko} alt='choko' width={100} height={20} />
                  </MenuItem>
                  <MenuItem value='prezo'>
                    <img src={prezo} alt='prezo' width={100} height={20} />
                  </MenuItem>
                  <MenuItem value='instagram'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Instagram width={20} height={20} />
                      <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Instagram</span>
                    </div>
                  </MenuItem>
                  {platforms.map((platform) => (
                    <MenuItem key={platform} value={platform}>
                      {platform === 'telegram' ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Telegram width={20} height={20} />
                          <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Telegram</span>
                        </div>
                      ) : null}
                      {platform === 'whatsapp' ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Whatsapp width={20} height={20} />
                          <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Whatsapp</span>
                        </div>
                      ) : null}
                      {platform === 'browser' ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Browser width={20} height={20} />
                          <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Browser</span>
                        </div>
                      ) : null}
                      {platform === 'boton' ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={botton} alt='boton' width={20} height={20} />
                          <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Boton</span>
                        </div>
                      ) : null}
                      {platform === 'tucomida' ? (
                        <div>
                          <BotoBot width={20} height={20} />
                          <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Botobot</span>
                        </div>
                      ) : null}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Modal>

        {/* Merma modal */}

        <Modal open={mermaModal} onClose={() => closeMermaModal()}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '500px',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <TextField
              fullWidth
              sx={{ marginBottom: '10px', position: 'sticky', top: 0, backgroundColor: 'white' }}
              label='Search'
              variant='outlined'
              onChange={(e) => setSearchMerma(e.target.value)}
            />
            {isLoadingMermaProducts || isFetchingMerma ? (
              <CircularProgress />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '300px', overflow: 'auto' }}>
                {mermaProducts &&
                  mermaProducts.map((product) => (
                    <div key={product.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <img src={product.img} width={50} height={50} alt={product.name} />
                      <div style={{ flex: 3, fontFamily: 'Montserrat, sans-serif' }}>{product.name}</div>
                      <Button sx={{ flex: 1 }} onClick={() => removeQuantity(product)}>
                        -
                      </Button>
                      <TextField
                        sx={{
                          flex: 1,
                        }}
                        // placeholder='quantity'
                        disabled
                        size='small'
                        value={selectedMerma.find((merma) => merma.id === product.id)?.quantity || 0}
                      />
                      <Button sx={{ flex: 1 }} onClick={() => addQuantity(product)}>
                        +
                      </Button>
                    </div>
                  ))}
              </div>
            )}
            <Button sx={{ marginTop: '10px' }} size='small' variant='contained' fullWidth onClick={() => createMerca()}>
              Create Merma
            </Button>
          </Box>
        </Modal>

        <FormControl sx={{ minWidth: 155, position: 'relative' }}>
          <InputLabel
            sx={{
              marginLeft: '32px',
              position: 'absolute',
              top: '-7px',
              fontFamily: 'Inter',
              color: '#176318e6',
              '&.Mui-focused': { color: '#06c0b0' },
            }}
          >
            Platform
          </InputLabel>
          <Select
            onChange={(e) => {
              setPlatform(e.target.value)
              setSearchPlatform(e.target.value)
            }}
            value={platform}
            size='small'
          >
            <MenuItem value=''>All</MenuItem>
            <MenuItem value='choco'>
              <img src={chocko} alt='choko' width={100} height={20} />
            </MenuItem>
            <MenuItem value='prezo'>
              <img src={prezo} alt='prezo' width={100} height={20} />
            </MenuItem>
            <MenuItem value='instagram'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Instagram width={20} height={20} />
                <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Instagram</span>
              </div>
            </MenuItem>
            {platforms.map((platform) => (
              <MenuItem key={platform} value={platform}>
                {platform === 'telegram' ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Telegram width={20} height={20} />
                    <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Telegram</span>
                  </div>
                ) : null}
                {platform === 'whatsapp' ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Whatsapp width={20} height={20} />
                    <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Whatsapp</span>
                  </div>
                ) : null}
                {platform === 'browser' ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Browser width={20} height={20} />
                    <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Browser</span>
                  </div>
                ) : null}
                {platform === 'boton' ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={botton} alt='boton' width={20} height={20} />
                    <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Boton</span>
                  </div>
                ) : null}
                {platform === 'tucomida' ? (
                  <div>
                    <BotoBot width={20} height={20} />
                    <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Botobot</span>
                  </div>
                ) : null}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <p
              onClick={() => {
                productsStatus === 2 ? setProductsStatus(0) : setProductsStatus(2)
                productsStatus === 2 ? setPerPage(50) : setPerPage(200)
              }}
              style={{
                // marginLeft: '10px',
                color: 'orange',
                cursor: 'pointer',
                border: productsStatus == 2 ? '1px solid orange' : 'none',
                padding: '5px',
                borderRadius: '5px',
                margin: '0 10px',
              }}
            >
              50%
            </p>
            <p
              onClick={() => {
                productsStatus === 3 ? setProductsStatus(0) : setProductsStatus(3)
                productsStatus === 3 ? setPerPage(50) : setPerPage(200)
              }}
              style={{
                // marginLeft: '10px',
                color: 'red',
                cursor: 'pointer',
                border: productsStatus == 3 ? '1px solid red' : 'none',
                padding: '5px',
                borderRadius: '5px',
                margin: '0 10px',
              }}
            >
              0%
            </p>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <p
              onClick={() => {
                if (partOfDay === 'PLM') {
                  setPartOfDay('')
                } else {
                  setPartOfDay('PLM')
                }
              }}
              style={{ border: partOfDay == 'PLM' ? '1px solid orange' : 'none', margin: 0, padding: '0 10px', cursor: 'pointer' }}
            >
              ☀️
            </p>
            <p
              onClick={() => {
                if (partOfDay === 'PLT') {
                  setPartOfDay('')
                } else {
                  setPartOfDay('PLT')
                }
              }}
              style={{ border: partOfDay == 'PLT' ? '1px solid red' : 'none', margin: 0, padding: '0 10px', cursor: 'pointer' }}
            >
              🌛
            </p>
            <CustomDatePicker value={simpleDeliveryDate} setValue={setSimpleDeliveryDate} />
          </Box>
        </Box>
      </div>
      {/* new order and search */}
      <div className='tableHeader__body' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        {mobileShow && (
          <div
            onClick={() => setOpen(true)}
            style={{ cursor: 'pointer', width: '100px', marginRight: 'auto' }}
            className='tableHeader__body__btn active'
          >
            New Order
          </div>
        )}
        {mobileShow && (
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <p
                onClick={() => {
                  productsStatus === 2 ? setProductsStatus(0) : setProductsStatus(2)
                  productsStatus === 2 ? setPerPage(50) : setPerPage(200)
                }}
                style={{
                  // marginLeft: '10px',
                  color: 'orange',
                  cursor: 'pointer',
                  border: productsStatus == 2 ? '1px solid orange' : 'none',
                  padding: '0 5px',
                  borderRadius: '5px',
                  margin: '5px 0 ',
                }}
              >
                50%
              </p>
              <p
                onClick={() => {
                  productsStatus === 3 ? setProductsStatus(0) : setProductsStatus(3)
                  productsStatus === 3 ? setPerPage(50) : setPerPage(200)
                }}
                style={{
                  // marginLeft: '10px',
                  color: 'red',
                  cursor: 'pointer',
                  border: productsStatus == 3 ? '1px solid red' : 'none',
                  padding: '0 5px',
                  borderRadius: '5px',
                  margin: '5px 0',
                }}
              >
                0%
              </p>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
              <p
                onClick={() => {
                  if (partOfDay === 'PLM') {
                    setPartOfDay('')
                  } else {
                    setPartOfDay('PLM')
                  }
                }}
                style={{ margin: 0, padding: '0 5px', cursor: 'pointer' }}
              >
                ☀️
              </p>
              <p
                onClick={() => {
                  if (partOfDay === 'PLT') {
                    setPartOfDay('')
                  } else {
                    setPartOfDay('PLT')
                  }
                }}
                style={{ margin: 0, padding: '0 5px', cursor: 'pointer' }}
              >
                🌛
              </p>
              <CustomDatePicker value={simpleDeliveryDate} setValue={setSimpleDeliveryDate} />
            </Box>
          </Box>
        )}
        {mobileShow &&
          (type == 'shipment' ? (
            <div style={{ cursor: 'pointer', width: '60px', marginRight: 'auto' }} className='tableHeader__body__btn active' onClick={createMerca}>
              {isLoading ? <CircularProgress size={20} /> : 'Merma'}
            </div>
          ) : null)}
        <div style={{ display: showSearch ? 'flex' : 'none' }} className='tableHeader__body__search '>
          <input type='text' placeholder='Search' onChange={(e) => searchHandler(e.target.value)} value={searchValue} />
          {isFetchingOrders && <CircularProgress />}
          <span>
            <img src={SearchIcon} alt='' />
          </span>
        </div>
        {/* <Search style={{ cursor: 'pointer' }} alt='search' width={27} height={27} onClick={() => setShowSearch(!showSearch)} /> */}
        {/* <img style={{ cursor: 'pointer' }} src={history} alt=' history' /> */}
        {mobileShow && <img onClick={() => setStatusModal(true)} style={{ cursor: 'pointer' }} src={filter} alt='filter' />}
        {/* <img style={{ cursor: 'pointer' }} src={print} alt='print' /> */}
        {/* <img onClick={() => setOpen(true)} style={{ cursor: 'pointer' }} src={newOrder} alt='newOrder' /> */}
      </div>
      <OrdersModal data={data} open={open} setOpen={setOpen} />
    </div>
  )
}
