const site = JSON.parse(localStorage.getItem('site'))

export const operations = [
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/history',
    name: 'Facturation',
    icon: 'OperationFacturation',
    enabled: true,
    disabled: false,
  },
  {
    permission: site?.avalible?.operations?.shopping,
    link: '/admin/shopping',
    name: `Shopping ${localStorage.getItem('name') === 'TPO' ? '' : 'CCN'}`,
    icon: 'OperationShopping',
    enabled: true,
    disabled: false,
  },
  {
    permission: site?.avalible?.operations?.shopping,
    link: '/admin/buyer',
    name: `Buyer ${localStorage.getItem('name') === 'TPO' ? '' : 'CCN'}`,
    icon: 'OperationBuyer',
    enabled: true,
    disabled: false,
  },
  {
    permission: site?.avalible?.operations?.shopping,
    link: '/admin/lowAnalyzer',
    name: 'Low profits',
    icon: 'OperationLowAnalyzer',
    enabled: true,
    disabled: false,
  },
  {
    permission: site?.avalible?.operations?.juise,
    link: '/admin/juice',
    name: `Production ${localStorage.getItem('name') === 'TPO' ? 'Juice' : 'CCN'}`,
    icon: 'OperationJuice',
    enabled: true,
    disabled: false,
  },
  {
    permission: site?.avalible?.operations?.Partnership,
    link: '/admin/shipment',
    name: 'Partnership',
    icon: 'OperationPartnership',
    enabled: true,
    disabled: false,
  },
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/SPO',
    name: 'SPO Management',
    icon: 'OperationShipmentPartners',
    enabled: localStorage.getItem('name') === 'TPO',
    disabled: false,
  },
  //Disabled links Functional Coming soon 
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/spoManagement',
    name: 'Special Offers',
    icon: 'OperationSPO',
    enabled: true,
    disabled: true,
  },
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/orders',
    name: 'Logistic list 🔜',
    icon: 'OperationLogisticList',
    enabled: true,
    disabled: true,
  },
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/orders',
    name: 'Picking 🔜',
    icon: 'OperationPicking',
    enabled: true,
    disabled: true,
  },
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/orders',
    name: 'Stock 🔜',
    icon: 'OperationStock',
    enabled: true,
    disabled: true,
  },
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/orders',
    name: 'Check List 🔜',
    icon: 'OperationCheckList',
    enabled: true,
    disabled: true,
  },
  {
    permission: site?.avalible?.operations?.facturas,
    link: '/admin/orders',
    name: 'Statistic & Analitica 🔜',
    icon: 'OperationStatistic',
    enabled: true,
    disabled: true,
  },
]